import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col, message, Row,
  Space, Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import React, {
  useEffect
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { createMitekUsersService, getMitekUsersByIdService } from 'common/services/extends/mitekUser';
import { CreateMitekUsersParamsTypes } from 'common/services/extends/mitekUser/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { mitekUserSchema } from 'common/utils/schemas';

type EditMitekUsersDataTypes = {
  userId?: number;
  mitekId: string;
};

const EditMitekUser: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  const mainMethod = useForm<EditMitekUsersDataTypes>({
    resolver: yupResolver(mitekUserSchema),
    mode: 'onChange',
    defaultValues: {
      mitekId: '',
      userId: undefined,
    }
  });

  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));
  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  /* Queries */
  const { data: detailMitekUser, isLoading: loadingDetail } = useQuery(
    ['getDetailMitekUser', idParam],
    () => {
      if (idParam) {
        return getMitekUsersByIdService({ id: idParam });
      }
      return undefined;
    }
  );

  const { mutate: createMitekUserMutate, isLoading: createLoading } = useMutation(
    'createShop',
    createMitekUsersService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.MITEK_USER_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  /* Variables */
  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: CreateMitekUsersParamsTypes = {
      mitekId: mainData.mitekId,
      userId: mainData.userId || 0,
    };

    try {
      createMitekUserMutate(dataSubmit);
    } catch (error: any) {
      message.error(idParam ? t('message.updateError') : t('message.createError'));
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailMitekUser) {
      mainMethod.reset({
        mitekId: detailMitekUser.mitekUserData.mitekId,
        userId: detailMitekUser.user.id,
      });
    } else {
      mainMethod.reset();
    }
  }, [detailMitekUser, mainMethod]);

  /* Render */
  return (
    <div className="p-editShop">
      <HeaderPage
        fixed
        title={idParam ? t('mitekUsers.edit') : `${t('mitekUsers.create')}`}
        rightHeader={!idParam ? (
          <Button
            type="primary"
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            onClick={() => handleSubmit()}
            loading={createLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        ) : null}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {loadingDetail ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <FormProvider {...mainMethod}>
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('mitekUsers.mitekId')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="mitekId"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="large"
                                  disabled={!!idParam}
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input u-mt-12">
                            <Typography.Text strong>
                              {t('mitekUsers.userId')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="userId"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <DropdownElement
                                  type="user"
                                  placeholder="---"
                                  value={value}
                                  locale={localeParams}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                  disabled={!!idParam}
                                />
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </FormProvider>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={detailMitekUser ? dayjs(detailMitekUser?.mitekUserData.createdAt).fromNow() : ''}
              createdBy=""
              lastUpdated={detailMitekUser ? dayjs(detailMitekUser?.mitekUserData.updatedAt).fromNow() : ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditMitekUser;
