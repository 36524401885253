import React from 'react';
import { useTranslation } from 'react-i18next';

import roles from 'configs/roles';

const useRoundDropdown = (rolesList: string[]) => {
  const { t } = useTranslation();
  const isFull = rolesList.includes(roles.LUCKY_DRAW_ROUND_ONE)
    && rolesList.includes(roles.LUCKY_DRAW_ROUND_TWO)
    && rolesList.includes(roles.LUCKY_DRAW_ROUND_THREE);

  const dropdownData: OptionType[] = React.useMemo(() => ([
    ...(isFull ? [{ label: t('system.all'), value: 'all' }] : []),
    ...(rolesList.includes(roles.LUCKY_DRAW_ROUND_ONE) ? [{ label: t('luckyDrawMatch.roundOne'), value: 'round1' }] : []),
    ...(rolesList.includes(roles.LUCKY_DRAW_ROUND_TWO) ? [{ label: t('luckyDrawMatch.roundTwo'), value: 'round2' }] : []),
    ...(rolesList.includes(roles.LUCKY_DRAW_ROUND_THREE) ? [{ label: t('luckyDrawMatch.roundThree'), value: 'round3' }] : []),
  ]), [isFull, rolesList, t]);
  return dropdownData;
};

export default useRoundDropdown;
