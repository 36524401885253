/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DollarOutlined,
  EyeOutlined, ReloadOutlined, SaveOutlined, ShareAltOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card, Col, Divider, Empty, Image,
  Modal, Radio, Row, Space, Spin, Table, Timeline, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import DetailModal from './detailModal';

import { renderReasonFailSMS } from '.';

import { useAppDispatch, useAppSelector } from 'app/store';
import { setSendSMSProcess, setTopupProcess } from 'app/systemSlice';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import Pulldown from 'common/components/Pulldown';
import SelectFile from 'common/components/SelectFile';
import {
  getPrizeByIdService,
  publishPrizeService,
  resendSMSService,
  topupBuyCardForTelCoService,
  topupBuyCardService,
  topupFouthPrizeService,
  updateProfileByIdService
} from 'common/services/extends/luckyDrawMatch';
import {
  LuckyDrawMatchProfileStatus,
  StatusTopup,
  UpdateProfileParamsTypes,
  ValidProfileHistories
} from 'common/services/extends/luckyDrawMatch/type';
import { getAllShopsService } from 'common/services/extends/shops';
import { topupManualService } from 'common/services/luckyDrawMatches';
import { LuckyDrawMatchStatus, LuckyDrawStatus } from 'common/services/luckyDrawMatches/types';
import { TEL_CO } from 'common/utils/constant';
import { formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import { congratulation } from 'common/utils/schemas';
import roles from 'configs/roles';

type FormType = {
  fullName: string
  phone: string
  shopName: string
  shopAddress: string
  idType: string
  idNumber: string
  receiverAddress: string
  senderAddress: string
  idFrontImage?: string
  idBackImage?: string
  passportImage?: string
  smsLuckyCodeImage: string
  smsMatchImage: string
  productSeriesImage: string
  customerWithProductImage: string
};

type InfoWinPrize = {
  luckyCode: string;
  prize: string;
  productName: string;
  modelName: string;
  seriesNumber: string;
  status: LuckyDrawStatus;
};

type StatusInfo = {
  status: LuckyDrawMatchStatus;
  reasonFailSMS: string[];
  profileStatus?: number;
  note: string;
};

type StatusTopupType = {
  status: LuckyDrawMatchStatus;
  reasonFailSMS: string[];
  topupStatus?: StatusTopup | null;
  note: string;
};

const EditLuckyDrawMatch: React.FC<ActiveRoles> = ({ roleOther, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const topupProcess = useAppSelector((state) => state.system.topupProcess);
  const sendSMSProcess = useAppSelector((state) => state.system.sendSMSProcess);
  const [topupLoading, setTopupLoading] = useState(false);
  const [sendSMSLoading, setSendSMSLoading] = useState(false);
  const [openPopupBuyCardForTelCo, setPopupBuyCardForTelCo] = useState(false);
  const [telCo, setTelCo] = useState<OptionType>();

  const mainMethod = useForm<FormType>({
    resolver: yupResolver(congratulation),
    mode: 'onChange',
  });

  const idType = useWatch({ control: mainMethod.control, name: 'idType' });

  /* States */
  const idParams = Number(searchParams.get('id'));
  const [open, setOpen] = useState(false);
  const [typeAction, setTypeAction] = useState('');
  const [historyId, setHistoryId] = useState<number | undefined>();

  /* Queries */
  const { data: prizeByIdData, isLoading: prizeLoading } = useQuery(
    ['getPrizeById', idParams],
    () => {
      if (idParams) {
        return getPrizeByIdService({
          id: idParams,
        });
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );

  const { data: shopsData } = useQuery(
    ['getShopData'],
    () => getAllShopsService(),
  );

  const { mutate: publishMutate, isLoading: publishLoading } = useMutation(
    ['publishPrize'],
    publishPrizeService,
    {
      onSuccess: () => {
        message.success(t('message.publishSuccess'));
      },
      onError: () => {
        message.error(t('message.publishFail'));
      }
    }
  );

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(
    ['updateProfile'],
    async (data: {
      id: number;
      params: UpdateProfileParamsTypes
    }) => updateProfileByIdService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: resendSMSMutate } = useMutation(
    ['resendSMS'],
    resendSMSService,
    {
      onError: () => {
        message.error(t('message.resendSMSFail'));
        setSendSMSLoading(false);
      }
    }
  );

  const { mutate: topupManualMutate } = useMutation(
    ['topupManual'],
    topupManualService,
    {
      onError: () => {
        message.error(t('message.topupManualFail'));
        setTopupLoading(false);
      }
    }
  );

  const { mutate: buycardMutate } = useMutation(
    ['buycard'],
    topupBuyCardService,
    {
      onError: () => {
        message.error(t('message.buyCardFail'));
        setTopupLoading(false);
      }
    }
  );

  const { mutate: buyCardForTelCoMute, isLoading: isLoadingBuyCardForTel } = useMutation(
    ['buyCardForTelCo'],
    topupBuyCardForTelCoService,
    {
      onSuccess: () => {
        message.success(t('message.buyCardSuccess'));
        queryClient.invalidateQueries(['getPrizeById', idParams]);
      },
      onError: () => {
        message.error(t('message.buyCardFail'));
      },
      onSettled: () => {
        setTelCo(undefined);
        setPopupBuyCardForTelCo(false);
      }
    }
  );

  const { mutate: topupFouthPrizeMutate, isLoading: topupFouthPrizeLoading } = useMutation(
    ['topupFouthPrize'],
    topupFouthPrizeService,
    {
      onSuccess: () => {
        message.success(t('message.topupFourthPrizeSuccess'));
      },
      onError: () => {
        message.error(t('message.topupFourthPrizeFail'));
      }
    }
  );

  const prizeBillData = useMemo(() => {
    if (prizeByIdData?.bill && Object.keys(prizeByIdData.bill).length > 0) {
      return {
        name: prizeByIdData?.bill?.fullName,
        phone: prizeByIdData?.bill?.phone,
        email: prizeByIdData?.bill?.email,
        address: prizeByIdData?.bill?.address,
        wardName: prizeByIdData?.bill?.wardName,
        districtName: prizeByIdData?.bill?.districtName,
        provinceName: prizeByIdData?.bill?.provinceName,
        buyDate: prizeByIdData?.bill?.buyDate ? dayjs(prizeByIdData?.bill?.buyDate).format('DD/MM/YYYY') : '',
        invoiceId: prizeByIdData?.bill?.invoiceId,
        shopAddress: prizeByIdData?.bill?.shopAddress,
        shopName: prizeByIdData?.bill?.shopName,
      };
    }
    return null;
  }, [prizeByIdData]);

  const formattedShopData = useMemo(() => shopsData?.data
    ?.map((item) => item.shopData.name), [shopsData]);

  const prizeMainData = useMemo(() => ({
    name: prizeByIdData?.profile?.fullName || '', // a
    phone: formatPhoneVietnamese(prizeByIdData?.profile?.phone) || '', // b
    shopName: prizeByIdData?.profile?.shopName || '', // c
    receiverAddress: prizeByIdData?.profile?.receiverAddress || '', // d
    shopAddress: prizeByIdData?.bill?.shopAddress, // e
    idNumber: prizeByIdData?.profile?.idNumber, // f
    idFrontImage: prizeByIdData?.profile?.idFrontImage, // g
    idBackImage: prizeByIdData?.profile?.idBackImage, // g
    smsLuckyCodeImage: prizeByIdData?.profile?.smsLuckyCodeImage, // h
    smsMatchImage: prizeByIdData?.profile?.smsMatchImage, // i
    productSeriesImage: prizeByIdData?.profile?.productSeriesImage, // j
    customerWithProductImage: prizeByIdData?.profile?.customerWithProductImage, // k
  }), [prizeByIdData]);

  const detectPrize = (prizeParams: string) => {
    if (prizeParams === '1st') return t('submitttedBill.firstPrize');
    if (prizeParams === '2nd') return t('submitttedBill.secondPrize');
    if (prizeParams === '3rd') return t('submitttedBill.thirdPrize');
    if (prizeParams === '4th') return t('submitttedBill.fourthPrize');
    return '';
  };

  const mappingFieldStatus = (status: LuckyDrawStatus) => {
    switch (status) {
      case 'valid':
        return t('system.valid');
      case 'invalid':
        return t('system.inValid');
      default:
        return t('submitttedBill.statusNew');
    }
  };

  const mappingProfileFieldStatus = (status: LuckyDrawMatchProfileStatus) => {
    switch (status) {
      case '1':
        return t('luckyDrawMatch.historyPending');
      case '7':
        return t('system.valid');
      case '9':
        return t('system.inValid');
      default:
        return t('submitttedBill.statusNew');
    }
  };

  const handleStatusCustomerInfo = (status: LuckyDrawMatchStatus) => {
    switch (status) {
      case 1:
        return t('luckyDrawMatch.pending');
      case 7:
        return t('luckyDrawMatch.delivered');
      default:
        return t('luckyDrawMatch.failSMS');
    }
  };

  const handleProfileStatusInfo = (status?: number) => {
    switch (status) {
      case 1:
        return t('luckyDrawMatch.processing');
      case 7:
        return t('luckyDrawMatch.valid');
      case 9:
        return t('luckyDrawMatch.invalid');
      default:
        return t('luckyDrawMatch.notyetProfile');
    }
  };

  const renderTopupStatus = (status?: StatusTopup | null) => {
    switch (status) {
      case 'fail':
        return t('luckyDrawMatch.failTopup');
      case 'success':
        return t('luckyDrawMatch.successTopup');
      case 'processing':
        return t('luckyDrawMatch.processingTopup');
      case 'postpaid':
        return t('luckyDrawMatch.postpaidTopup');
      default:
        return '';
    }
  };

  const columns: ColumnsType<InfoWinPrize> = [
    {
      title: t('submitttedBill.luckyCode'),
      key: 'luckyCode',
      width: 250,
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {data.luckyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.prize'),
      key: 'prize',
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {detectPrize(data.prize)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.productName'),
      key: 'productName',
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {data.productName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.modelName'),
      key: 'modelName',
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {data.modelName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.seriesNumber'),
      key: 'seriesNumber',
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {data.seriesNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.prizeStatus'),
      key: 'status',
      render: (_name: string, data: InfoWinPrize) => (
        <Typography.Text>
          {mappingFieldStatus(data.status)}
        </Typography.Text>
      ),
    },
  ];

  const historyColumn: ColumnsType<ValidProfileHistories> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.from'),
      key: 'from',
      width: 120,
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {mappingProfileFieldStatus(data.fromStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.to'),
      key: 'to',
      width: 120,
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {mappingProfileFieldStatus(data.toStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.user'),
      key: 'user',
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {data.user.name}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.doAt'),
      key: 'user',
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'email',
      render: (_name: string, data: ValidProfileHistories) => (
        <Typography.Text>
          {data.user.email}
        </Typography.Text>
      ),
    },
  ];

  const infoColumns: ColumnsType<StatusInfo> = [
    {
      title: t('luckyDrawMatch.smsStatus'),
      key: 'status',
      render: (_name: string, data: StatusInfo) => (
        <Typography.Text>
          {handleStatusCustomerInfo(data.status)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.reasonFailSMS'),
      key: 'reasonFailSMS',
      render: (_name: string, data: StatusInfo) => (
        <Timeline
          items={data.reasonFailSMS.map((item) => ({ children: t(renderReasonFailSMS(item)) }))}
        />
      ),
    },
    {
      title: t('luckyDrawMatch.profileStatus'),
      key: 'modelName',
      render: (_name: string, data: StatusInfo) => (
        <Typography.Text>
          {handleProfileStatusInfo(data.profileStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.note'),
      key: 'seriesNumber',
      render: (_name: string, data: StatusInfo) => (
        <Typography.Text>
          {data.note}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.history'),
      key: 'action',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (_name: string, data: StatusInfo) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => setHistoryId(prizeByIdData?.product.productId)}
          />
        </Space>
      ),
    },
  ];
  const topupColumns: ColumnsType<StatusTopupType> = [
    {
      title: t('luckyDrawMatch.smsStatus'),
      key: 'status',
      render: (_name: string, data: StatusTopupType) => (
        <Typography.Text>
          {handleStatusCustomerInfo(data.status)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.reasonFailSMS'),
      key: 'reasonFailSMS',
      render: (_name: string, data: StatusTopupType) => (
        <Timeline
          items={data.reasonFailSMS.map((item) => ({ children: t(renderReasonFailSMS(item)) }))}
        />
      ),
    },
    {
      title: t('luckyDrawMatch.topupStatus'),
      dataIndex: 'topupStatus',
      key: 'topupStatus',
      render: (_name: string, data: StatusTopupType) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {t(renderTopupStatus(data.topupStatus))}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.note'),
      key: 'seriesNumber',
      render: (_name: string, data: StatusTopupType) => (
        <Typography.Text>
          {data.note}
        </Typography.Text>
      ),
    },
  ];

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    if (idType === 'identity') {
      mainData.passportImage = '';
    } else {
      mainData.idFrontImage = '';
      mainData.idBackImage = '';
    }

    const dataSubmit: UpdateProfileParamsTypes = {
      idNumber: mainData.idNumber,
      receiverAddress: mainData.receiverAddress,
      senderAddress: mainData.senderAddress,
      idFrontImage: mainData.idFrontImage || '',
      idBackImage: mainData.idBackImage || '',
      smsLuckyCodeImage: mainData.smsLuckyCodeImage,
      smsMatchImage: mainData.smsMatchImage,
      productSeriesImage: mainData.productSeriesImage,
      customerWithProductImage: mainData.customerWithProductImage,
      passportImage: mainData.passportImage || ''
    };

    try {
      updateProfileMutate({
        id: idParams,
        params: dataSubmit
      });
    } catch (error: any) {
      message.error(idParams ? t('message.updateError') : t('message.createError'));
    }
  };

  const infoWinPrizeData: InfoWinPrize[] = useMemo(() => ([
    {
      luckyCode: prizeByIdData?.mainData.luckyCode || '',
      prize: prizeByIdData?.mainData.prize || '',
      productName: prizeByIdData?.product?.productName || '',
      modelName: prizeByIdData?.product?.modelName || '',
      seriesNumber: prizeByIdData?.product?.seriesNumber || '',
      status: prizeByIdData?.mainData.newStatus || 'new',
    }
  ]), [prizeByIdData]);

  const statusCustomerInfo: StatusInfo[] = useMemo(() => (!prizeByIdData ? ([]) : ([{
    status: prizeByIdData?.mainData?.status || 1,
    reasonFailSMS: prizeByIdData?.mainData?.reasonFailSMS || [],
    profileStatus: prizeByIdData?.profile?.status,
    note: prizeByIdData?.profile?.note || '',
  }])), [prizeByIdData]);

  const statusTopupInfo: StatusTopupType[] = useMemo(() => (!prizeByIdData ? ([]) : ([{
    status: prizeByIdData?.mainData?.status || 1,
    reasonFailSMS: prizeByIdData?.mainData?.reasonFailSMS || [],
    topupStatus: prizeByIdData?.mainData.statusTopup,
    note: prizeByIdData?.profile?.note || '',
  }])), [prizeByIdData]);

  const historyData: ValidProfileHistories[] = useMemo(
    () => prizeByIdData?.profile?.validProfileHistories || [],
    [prizeByIdData]
  );

  useEffect(() => {
    if (prizeByIdData?.profile) {
      mainMethod.reset({
        fullName: prizeByIdData.profile?.fullName || '',
        phone: prizeByIdData?.bill?.phone ? formatPhoneVietnamese(prizeByIdData?.bill?.phone) : '',
        receiverAddress: prizeByIdData.profile?.receiverAddress || '',
        senderAddress: prizeByIdData.profile?.senderAddress || '',
        shopName: prizeByIdData.profile?.shopName || '',
        shopAddress: prizeByIdData.profile?.shopAddress || '',
        idNumber: prizeByIdData.profile?.idNumber || '',
        idType: prizeByIdData.profile?.passportImage ? 'passport' : 'identity',
        idFrontImage: prizeByIdData.profile?.idFrontImage || '',
        idBackImage: prizeByIdData.profile?.idBackImage || '',
        smsLuckyCodeImage: prizeByIdData.profile?.smsLuckyCodeImage || '',
        smsMatchImage: prizeByIdData.profile?.smsMatchImage || '',
        productSeriesImage: prizeByIdData.profile?.productSeriesImage || '',
        customerWithProductImage: prizeByIdData.profile?.customerWithProductImage || '',
        passportImage: prizeByIdData.profile?.passportImage || '',
      });
    } else {
      mainMethod.reset({
        idType: 'identity'
      });
    }
  }, [prizeByIdData, mainMethod]);

  useEffect(() => {
    if (topupProcess.id === idParams && topupLoading) {
      setTopupLoading(false);
      queryClient.invalidateQueries(['getPrizeById', idParams]);
      dispatch(setTopupProcess({ id: undefined, status: 'none' }));
      if (topupProcess.status === 'fail') {
        message.error(topupProcess.type === 'buycard' ? t('message.buyCardFail') : t('message.topupManualFail'));
      }
    }
  }, [dispatch, idParams, queryClient, t, topupLoading, topupProcess]);

  useEffect(() => {
    if (sendSMSProcess.id === idParams && sendSMSLoading) {
      setSendSMSLoading(false);
      message.success(t('message.resendSMSSuccess'));
      queryClient.invalidateQueries(['getPrizeById', idParams]);
      dispatch(setSendSMSProcess({ id: undefined, status: 1 }));
      if (sendSMSProcess.status === 9) {
        message.error(t('message.resendSMSFail'));
      }
    }
  }, [dispatch, idParams, queryClient, sendSMSLoading, sendSMSProcess, t]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.luckyDrawDetail')}
        rightHeader={(
          <Space>
            {(roleOther.includes(roles.TOPUP_BUYCARD_FOR_TELCO) || rolesUser.includes('*'))
              && prizeByIdData?.mainData.statusTopup !== 'success' && prizeByIdData?.mainData.prize === '4th' && (
                <Button
                  type="primary"
                  onClick={() => {
                    setPopupBuyCardForTelCo(true);
                  }}
                >
                  <DollarOutlined />
                  {t('luckyDrawMatch.buyCardForTelButton')}
                </Button>
              )}
            {/* {roleOther.includes(roles.LUCKY_DRAW_MATCH_TOPUP_FOURTH_PRIZE) && prizeByIdData?.mainData.prize === '4th'
              && prizeByIdData.mainData.statusTopup !== 'success' && (
                <Button
                  type="primary"
                  loading={topupFouthPrizeLoading}
                  onClick={() => topupFouthPrizeMutate([prizeByIdData.mainData.id])}
                >
                  <DollarOutlined />
                  {t('luckyDrawMatch.topupFourthPrize')}
                </Button>
              )}
            {roleOther.includes(roles.LUCKY_DRAW_MATCH_PUBLISHALL) && (
              <Button
                type="primary"
                disabled={prizeByIdData?.mainData.status !== 1
                  || !!prizeByIdData?.mainData.statusTopup}
                loading={publishLoading || updateProfileLoading}
                onClick={() => prizeByIdData?.mainData.id
                  && publishMutate([prizeByIdData?.mainData.id])}
              >
                <ShareAltOutlined />
                {t('luckyDrawMatch.publish')}
              </Button>
            )} */}
          </Space>
        )}
      />
      <Spin spinning={prizeLoading}>
        {prizeByIdData ? (
          <div className="t-mainlayout_wrapper">
            <div className="modal-container">
              {/* Thông tin khách hàng */}
              <Card type="inner" style={{ marginTop: '16px', fontSize: '24px' }}>
                <Typography.Title level={4}>{t('luckyDrawMatch.profileInfo')}</Typography.Title>
                <Divider />
                <Row gutter={16}>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('system.name')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.name}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('system.phone')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {formatPhoneVietnamese(prizeBillData?.phone)}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('system.email')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.email}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('footer.address')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.address}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('submitttedBill.wardName')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.wardName}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('submitttedBill.districtName')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.districtName}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('submitttedBill.provinceName')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.provinceName}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('submitttedBill.buyDate')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.buyDate}
                    </Typography.Text>
                  </Col>
                  <Col span={8} style={{ marginBottom: '12px' }}>
                    <Typography.Text strong>
                      {t('submitttedBill.shopName')}
                      :
                    </Typography.Text>
                    <Typography.Text className="u-ml-4">
                      {prizeBillData?.shopName}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
              {/* Thông tin trúng thưởng */}
              <Card type="inner" style={{ marginTop: '16px' }}>
                <Typography.Title level={4}>{t('luckyDrawMatch.winPrizeInfo')}</Typography.Title>
                <Divider />
                <Table
                  loading={prizeLoading}
                  columns={columns}
                  dataSource={infoWinPrizeData}
                  pagination={false}
                  scroll={{ x: 986 }}
                />
              </Card>
              {/* Thông tin khách hàng xác nhận */}
              {prizeByIdData.mainData.prize !== '4th' ? (
                <Card type="inner" style={{ marginTop: '16px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Title level={4}>{t('luckyDrawMatch.confirmCustomerInfo')}</Typography.Title>
                    <div>
                      {roleUpdate && (
                        <Button
                          type="primary"
                          loading={publishLoading || updateProfileLoading}
                          disabled={!prizeByIdData.profile}
                          onClick={() => handleSubmit()}
                        >
                          <SaveOutlined />
                          {t('system.update')}
                        </Button>
                      )}
                      {/* <div className="u-mt-8">
                        {(roleOther.includes(roles.LUCKY_DRAW_MATCH_SENDSMS) || rolesUser.includes('*')) && (
                          <Button
                            type="primary"
                            loading={sendSMSLoading}
                            onClick={() => {
                              setSendSMSLoading(true);
                              resendSMSMutate(prizeByIdData.mainData.id);
                            }}
                          >
                            <ReloadOutlined />
                            {t('luckyDrawMatch.resendSMS')}
                          </Button>
                        )}
                      </div> */}

                      <div className="u-mt-8">
                        {prizeByIdData.profile?.status !== 7 && !!prizeByIdData.profile && (
                          <Button
                            disabled={!roleOther.includes(roles.LUCKY_DRAW_MATCH_VALID)}
                            onClick={() => {
                              setOpen(true);
                              setTypeAction('valid');
                            }}
                            style={{ width: '100%' }}
                            type="primary"
                          >
                            {t('system.valid')}
                          </Button>
                        )}
                      </div>
                      <div className="u-mt-8">
                        {prizeByIdData.profile?.status !== 9 && !!prizeByIdData.profile && (
                          <Button
                            disabled={!roleOther.includes(roles.LUCKY_DRAW_MATCH_INVALID)}
                            onClick={() => {
                              setOpen(true);
                              setTypeAction('invalid');
                            }}
                            danger
                          >
                            {t('system.inValid')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="u-mt-16 u-mb-16">
                    <Table
                      columns={infoColumns}
                      dataSource={statusCustomerInfo}
                      pagination={false}
                    />
                  </div>
                  {roleUpdate && !!prizeByIdData.profile ? (
                    <FormProvider {...mainMethod}>
                      <Row gutter={16} className="u-mt-8">
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('system.name')}
                            :
                          </Typography.Text>
                          <Controller
                            name="fullName"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="fullName"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                readOnly
                              />
                            )}
                          />
                        </Col>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('system.phone')}
                            :
                          </Typography.Text>
                          <Controller
                            name="phone"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="phone"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                readOnly
                              />
                            )}
                          />
                        </Col>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('luckyDrawMatch.shopName')}
                            :
                          </Typography.Text>
                          <Controller
                            name="shopName"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="shopName"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                readOnly
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        {prizeByIdData?.profile?.shopName
                          && !formattedShopData?.includes(prizeByIdData?.profile?.shopName)
                          && (
                            <Col span={8} style={{ marginBottom: '12px' }}>
                              <Typography.Text strong>
                                {t('luckyDrawMatch.agentName')}
                                :
                              </Typography.Text>
                              <Controller
                                name="shopAddress"
                                defaultValue=""
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    className="u-mt-8"
                                    name="shopAddress"
                                    value={value}
                                    onChange={onChange}
                                    error={error?.message}
                                    size="large"
                                    readOnly
                                  />
                                )}
                              />
                            </Col>
                          )}
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('luckyDrawMatch.receiverAddress')}
                            :
                          </Typography.Text>
                          <Controller
                            name="receiverAddress"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="receiverAddress"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </Col>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('luckyDrawMatch.senderAddress')}
                            :
                          </Typography.Text>
                          <Controller
                            name="senderAddress"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="senderAddress"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Typography.Text strong>
                            {t('luckyDrawMatch.idType')}
                            :
                          </Typography.Text>
                          <Controller
                            name="idType"
                            render={({
                              field: { value, onChange },
                            }) => (
                              <div className="u-mt-8">
                                <Radio.Group
                                  onChange={onChange}
                                  value={value}
                                >
                                  <Radio value="identity">{t('luckyDrawMatch.identity')}</Radio>
                                  <Radio value="passport">{t('luckyDrawMatch.passport')}</Radio>
                                </Radio.Group>
                              </div>
                            )}
                          />
                          <div className="u-mt-16">
                            <Typography.Text strong>
                              {t('luckyDrawMatch.numberCCCD')}
                              :
                            </Typography.Text>
                            <Controller
                              name="idNumber"
                              defaultValue=""
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  name="idNumber"
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        {idType === 'identity' ? (
                          <>
                            <Col span={8} style={{ marginBottom: '12px' }}>
                              <Controller
                                name="idFrontImage"
                                defaultValue=""
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <SelectFile
                                    title={t('luckyDrawMatch.idFrontImage')}
                                    value={value}
                                    handleSelect={(data) => {
                                      onChange(data);
                                    }}
                                  />
                                )}
                              />
                            </Col>
                            <Col span={8} style={{ marginBottom: '12px' }}>
                              <Controller
                                name="idBackImage"
                                defaultValue=""
                                render={({
                                  field: { value, onChange },
                                  fieldState: { error },
                                }) => (
                                  <SelectFile
                                    title={t('luckyDrawMatch.idBackImage')}
                                    value={value}
                                    handleSelect={(data) => {
                                      onChange(data);
                                    }}
                                  />
                                )}
                              />
                            </Col>
                          </>
                        ) : (
                          <Col span={8} style={{ marginBottom: '12px' }}>
                            <Controller
                              name="passportImage"
                              defaultValue=""
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <SelectFile
                                  title={t('luckyDrawMatch.passportImage')}
                                  value={value}
                                  handleSelect={(data) => {
                                    onChange(data);
                                  }}
                                />
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row gutter={16}>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Controller
                            name="smsLuckyCodeImage"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <SelectFile
                                title={t('luckyDrawMatch.smsLuckyCodeImage')}
                                value={value}
                                handleSelect={(data) => {
                                  onChange(data);
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Controller
                            name="smsMatchImage"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <SelectFile
                                title={t('luckyDrawMatch.smsMatchImage')}
                                value={value}
                                handleSelect={(data) => {
                                  onChange(data);
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Controller
                            name="productSeriesImage"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <SelectFile
                                title={t('luckyDrawMatch.productSeriesImage')}
                                value={value}
                                handleSelect={(data) => {
                                  onChange(data);
                                }}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8} style={{ marginBottom: '12px' }}>
                          <Controller
                            name="customerWithProductImage"
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <SelectFile
                                title={t('luckyDrawMatch.customerWithProductImage')}
                                value={value}
                                handleSelect={(data) => {
                                  onChange(data);
                                }}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </FormProvider>
                  ) : prizeByIdData.profile ? (
                    <Row gutter={16}>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('system.name')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {prizeMainData.name}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('system.phone')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {formatPhoneVietnamese(prizeMainData.phone)}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.shopName')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {prizeMainData.shopName}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.receiverAddress')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {prizeMainData.receiverAddress}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.shopAddress')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {prizeMainData.shopAddress}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.numberCCCD')}
                          :
                        </Typography.Text>
                        <Typography.Text className="u-ml-4">
                          {prizeMainData.idNumber}
                        </Typography.Text>
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.idFrontImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.idFrontImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.idFrontImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.idBackImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.idBackImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.idBackImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.smsLuckyCodeImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.smsLuckyCodeImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.smsLuckyCodeImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.smsMatchImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.smsMatchImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.smsMatchImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.productSeriesImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.productSeriesImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.productSeriesImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                      <Col span={8} style={{ marginBottom: '12px' }}>
                        <Typography.Text strong>
                          {t('luckyDrawMatch.customerWithProductImage')}
                          :
                        </Typography.Text>
                        {
                          prizeMainData.customerWithProductImage && (
                            <div>
                              <Image
                                width={200}
                                src={prizeMainData.customerWithProductImage}
                              />
                            </div>
                          )
                        }
                      </Col>
                    </Row>
                  ) : null}
                </Card>
              ) : (
                <Card type="inner" style={{ marginTop: '16px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Title level={4}>{t('luckyDrawMatch.confirmCustomerInfo')}</Typography.Title>
                    <Space>
                      {/* {(roleOther.includes(roles.LUCKY_DRAW_MATCH_SENDSMS) || rolesUser.includes('*')) && (
                        <Button
                          type="primary"
                          loading={sendSMSLoading}
                          onClick={() => {
                            setSendSMSLoading(true);
                            resendSMSMutate(prizeByIdData.mainData.id);
                          }}
                        >
                          <ReloadOutlined />
                          {t('luckyDrawMatch.resendSMS')}
                        </Button>
                      )} */}
                      {/* {roleOther.includes(roles.LUCKY_DRAW_MATCH_TOPUP_MANUAL) && (
                        <Button
                          type="primary"
                          loading={topupLoading}
                          onClick={() => {
                            setTopupLoading(true);
                            topupManualMutate(prizeByIdData.mainData.id);
                          }}
                        >
                          <ReloadOutlined />
                          {t('luckyDrawMatch.topupManual')}
                        </Button>
                      )} */}
                      {/* {(roleOther.includes(roles.TOPUP_BUYCARD) || rolesUser.includes('*')) && (
                        prizeByIdData.mainData.statusTopup === 'fail' || prizeByIdData.mainData.statusTopup === 'postpaid') && (
                          <Button
                            type="primary"
                            loading={topupLoading}
                            onClick={() => {
                              setTopupLoading(true);
                              buycardMutate([prizeByIdData.mainData.id]);
                            }}
                          >
                            <DollarOutlined />
                            {t('luckyDrawMatch.topup')}
                          </Button>
                        )} */}
                    </Space>
                  </div>
                  <div className="u-mt-16 u-mb-16">
                    <Table
                      columns={topupColumns}
                      dataSource={statusTopupInfo}
                      pagination={false}
                    />
                  </div>
                </Card>
              )}
            </div>
          </div>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        <DetailModal
          isOpen={open}
          id={idParams}
          handleClose={() => setOpen(false)}
          type={typeAction}
        />
        <Modal
          width="80vw"
          bodyStyle={{ paddingTop: '40px' }}
          open={!!historyId}
          onCancel={() => setHistoryId(undefined)}
          footer={false}
        >
          <Table
            dataSource={historyData}
            pagination={false}
            columns={historyColumn}
            scroll={{ x: 986 }}
          />
        </Modal>
        <Modal
          width="80vw"
          bodyStyle={{ paddingTop: '40px' }}
          open={openPopupBuyCardForTelCo}
          onCancel={() => setPopupBuyCardForTelCo(false)}
          footer={false}
        >
          <Card
            type="inner"
            style={{
              minHeight: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Space>
                  <Typography.Title level={4}>{t('luckyDrawMatch.telco')}</Typography.Title>
                  <Pulldown
                    value={telCo}
                    placeholder={`${t('system.select')} ${t('luckyDrawMatch.telco')}`}
                    options={TEL_CO}
                    handleSelect={(option) => setTelCo(option)}
                  />
                </Space>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Button
                  type="primary"
                  loading={sendSMSLoading}
                  disabled={!telCo?.value || isLoadingBuyCardForTel}
                  onClick={() => {
                    if (prizeByIdData && telCo && telCo.value) {
                      buyCardForTelCoMute({
                        luckyDrawMatchId: prizeByIdData.mainData.id,
                        telco: String(telCo.value)
                      });
                    }
                  }}
                >
                  <DollarOutlined />
                  {t('luckyDrawMatch.buyCardForTelButton')}
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </Spin>
    </>
  );
};

export default EditLuckyDrawMatch;
