import { SaveOutlined } from '@ant-design/icons';
import {
  Typography, message, Card, Button, Upload, Space, DatePicker, Row, Col
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';
import React, {
  useEffect, useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import Input from 'common/components/Input';
import { getSystemGeneralService, updateSystemGeneralService } from 'common/services/systems';
import { UpdateSystemGeneralParams } from 'common/services/systems/types';
import { getImageURL } from 'common/utils/functions';

type SystemGeneralForm = {
  gaIds: string;
  gtmIds: string;
  gMapId: string;
  fileFavicon?: File;
  luckyDrawMatchRange?: string[];
  luckyDrawMatchSecondRoundRange?: string[];
  luckyDrawMatchThirdRoundRange?: string[];
  luckyDrawFirstDuration: number;
  luckyDrawSecondDuration: number;
  luckyDrawThirdDuration: number;
  luckyDrawFourthDuration: number;
  endedEventTime: string;
};

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const GeneralSM: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  /* React-hooks-form */
  const method = useForm<SystemGeneralForm>({
    mode: 'onSubmit',
    defaultValues: {
      gaIds: '',
      gtmIds: '',
      gMapId: '',
      fileFavicon: undefined,
      luckyDrawMatchRange: undefined,
      luckyDrawMatchSecondRoundRange: undefined,
      luckyDrawMatchThirdRoundRange: undefined,
      luckyDrawFirstDuration: undefined,
      luckyDrawSecondDuration: undefined,
      luckyDrawThirdDuration: undefined,
      luckyDrawFourthDuration: undefined,
      endedEventTime: '',
    },
  });

  /* Queries */
  const {
    data: generalData,
  } = useQuery(
    ['systemsManagement-general', 'list'],
    () => getSystemGeneralService(),
  );

  const { mutate: editGeneralMutate, isLoading: isEditLoading } = useMutation(
    ['systemsManagement-general', 'edit'],
    async (params: UpdateSystemGeneralParams) => updateSystemGeneralService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  /* Functions */
  const handleSubmit = (data: SystemGeneralForm) => {
    const submitData = {
      ...data,
      luckyDrawFirstDuration: Number(data.luckyDrawFirstDuration),
      luckyDrawSecondDuration: Number(data.luckyDrawSecondDuration),
      luckyDrawThirdDuration: Number(data.luckyDrawThirdDuration),
      luckyDrawFourthDuration: Number(data.luckyDrawFourthDuration),
      endedEventTime: dayjs(data.endedEventTime).toISOString(),
    };
    editGeneralMutate(submitData);
  };

  /* Effects */
  useEffect(() => {
    if (generalData) {
      method.reset({
        gaIds: generalData.gaIds?.toString(),
        gtmIds: generalData.gtmIds?.toString(),
        gMapId: generalData.gMapId,
        luckyDrawMatchRange: [generalData.luckyDrawMatchStart, generalData.luckyDrawMatchEnd],
        luckyDrawMatchSecondRoundRange: [generalData.luckyDrawMatchSecondRoundStart,
        generalData.luckyDrawMatchSecondRoundEnd],
        luckyDrawMatchThirdRoundRange: [generalData.luckyDrawMatchThirdRoundStart,
        generalData.luckyDrawMatchThirdRoundEnd],
        luckyDrawFirstDuration: generalData.luckyDrawFirstDuration,
        luckyDrawSecondDuration: generalData.luckyDrawSecondDuration,
        luckyDrawThirdDuration: generalData.luckyDrawThirdDuration,
        luckyDrawFourthDuration: generalData.luckyDrawFourthDuration,
        endedEventTime: generalData.endedEventTime,
      });
      setFileList([{
        uid: '-1',
        name: generalData?.favicon?.split('/').slice(-1)[0] || 'favicon.ico',
        url: getImageURL(generalData?.favicon),
        thumbUrl: getImageURL(generalData?.favicon),
      }]);
    }
  }, [generalData, method]);

  return (
    <div className="p-system_general">
      <FormProvider<SystemGeneralForm> {...method}>
        <form noValidate>
          <Card type="inner">
            <Space className="p-system_general_space" direction="vertical" size={16}>
              <Controller
                name="gaIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <div>
                    <Typography.Text strong>
                      {t('systemManagement.gaId')}
                      {' '}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </div>
                )}
              />

              <Controller
                name="gtmIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gtmId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="gMapId"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gMapId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="fileFavicon"
                render={({ field: { onChange } }) => (
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(info) => {
                      onChange(info.fileList?.[0]?.originFileObj);
                      setFileList([...info.fileList]);
                    }}
                    onRemove={() => onChange(undefined)}
                    maxCount={1}
                    fileList={fileList}
                    accept="image/x-icon"
                  >
                    <Button
                      type="default"
                    >
                      {t('systemManagement.uploadFavicon')}
                    </Button>
                  </Upload>
                )}
              />

              <Controller
                name="luckyDrawMatchRange"
                control={method.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.luckyDrawMatchRange')}
                    </Typography.Text>
                    <RangePicker
                      className="u-mt-8"
                      allowClear
                      size="large"
                      value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
                      format={dateFormat}
                      onChange={(values) => values && onChange(values.map(
                        (item) => dayjs(item).toISOString()
                      ))}
                      style={{ width: '50%', display: 'flex' }}
                    />
                  </>
                )}
              />
              <Controller
                name="luckyDrawMatchSecondRoundRange"
                control={method.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.luckyDrawMatchSecondRange')}
                    </Typography.Text>
                    <RangePicker
                      className="u-mt-8"
                      allowClear
                      size="large"
                      value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
                      format={dateFormat}
                      onChange={(values) => values && onChange(values.map(
                        (item) => dayjs(item).toISOString()
                      ))}
                      style={{ width: '50%', display: 'flex' }}
                    />
                  </>
                )}
              />
              <Controller
                name="luckyDrawMatchThirdRoundRange"
                control={method.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.luckyDrawMatchThirdRange')}
                    </Typography.Text>
                    <RangePicker
                      className="u-mt-8"
                      allowClear
                      size="large"
                      value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
                      format={dateFormat}
                      onChange={(values) => values && onChange(values.map(
                        (item) => dayjs(item).toISOString()
                      ))}
                      style={{ width: '50%', display: 'flex' }}
                    />
                  </>
                )}
              />

              <Row>
                <Col span={12}>
                  <Controller
                    name="luckyDrawFirstDuration"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.luckyDrawFirstDuration')}
                        </Typography.Text>
                        <Input
                          type="number"
                          className="u-mt-8"
                          ref={ref}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="luckyDrawSecondDuration"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.luckyDrawSecondDuration')}
                        </Typography.Text>
                        <Input
                          type="number"
                          className="u-mt-8 u-ml-8"
                          ref={ref}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Controller
                    name="luckyDrawThirdDuration"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.luckyDrawThirdDuration')}
                        </Typography.Text>
                        <Input
                          type="number"
                          className="u-mt-8"
                          ref={ref}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="luckyDrawFourthDuration"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <Typography.Text strong>
                          {t('systemManagement.luckyDrawFourthDuration')}
                        </Typography.Text>
                        <Input
                          type="number"
                          className="u-mt-8 u-ml-8"
                          ref={ref}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>

              <Controller
                name="endedEventTime"
                control={method.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.endedEventTime')}
                    </Typography.Text>
                    <DatePicker
                      size="large"
                      className="u-mt-8"
                      value={dayjs(value || new Date())}
                      onChange={(val) => onChange(dayjs(val).format('YYYY-MM-DD HH:mm:ss'))}
                      format="DD/MM/YYYY HH:mm:ss"
                      showTime
                      style={{ width: '100%' }}
                      allowClear={false}
                    />
                  </>
                )}
              />

            </Space>
            <div className="p-system_general_submit u-mt-16">
              <Button
                type="primary"
                disabled={!canEdit}
                loading={isEditLoading}
                onClick={method.handleSubmit(handleSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            </div>
          </Card>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSM;
