import {
  Button,
  Col, Modal, Row, Typography, message
} from 'antd';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import Input from 'common/components/Input';
import { updateMachineSerialService } from 'common/services/extends/serials';
import { UpdateSerialsNumberParams } from 'common/services/extends/serials/types';

interface Props {
  isOpen: boolean;
  handleClose(): void;
}

interface RequestParams {
  phone: string;
  oldSeriesNumber: string;
  newSeriesNumber: string;
}

const UpdateDetailModal: React.FC<Props> = ({
  handleClose, isOpen
}) => {
  const { t } = useTranslation();

  const mainMethod = useForm<RequestParams>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      oldSeriesNumber: '',
      newSeriesNumber: '',
    }
  });

  const { mutate: updateMachineSerialMutate, isLoading: updateLoading } = useMutation(
    ['update-machine-serials'],
    (param: UpdateSerialsNumberParams) => updateMachineSerialService(param),
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
        handleClose();
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: UpdateSerialsNumberParams = {
      phone: mainData.phone,
      oldSeriesNumber: mainData.oldSeriesNumber,
      newSeriesNumber: mainData.newSeriesNumber,
    };

    try {
      updateMachineSerialMutate(dataSubmit);
    } catch (error: any) {
      message.error(t('message.updateError'));
    }
  };

  return (
    <Modal
      title={(
        <Typography.Title level={3} style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {t('serials.updateSerieNumber')}
        </Typography.Title>
      )}
      width="60vw"
      visible={isOpen}
      closable
      footer={null}
      onCancel={handleClose}
    >
      <div className="modal-container">
        <FormProvider {...mainMethod}>
          <Row
            gutter={16}
            className="u-mt-16"
          >
            <Col span={24}>
              <Typography.Text strong>
                {t('system.phone')}
              </Typography.Text>
              <Controller
                name="phone"
                defaultValue=""
                render={({
                  field: { value: fieldValue, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    name="phone"
                    value={fieldValue}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Col>
            <Col className="u-mt-8" span={24}>
              <Typography.Text strong>
                {t('serials.oldSeriesNumber')}
              </Typography.Text>
              <Controller
                name="oldSeriesNumber"
                defaultValue=""
                render={({
                  field: { value: fieldValue, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    name="oldSeriesNumber"
                    value={fieldValue}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Col>
            <Col className="u-mt-8" span={24}>
              <Typography.Text strong>
                {t('serials.newSeriesNumber')}
              </Typography.Text>
              <Controller
                name="newSeriesNumber"
                defaultValue=""
                render={({
                  field: { value: fieldValue, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    name="newSeriesNumber"
                    value={fieldValue}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className="u-mt-16"
                type="primary"
                onClick={() => handleSubmit()}
                disabled={updateLoading}
              >
                {t('system.ok')}
              </Button>
            </Col>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default UpdateDetailModal;
