import {
  CreateMitekUsersParamsTypes,
  MitekUsersTypes,
  GetAllMitekUsersParamsTypes,
  GetMitekUsersByIdParamsTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

/* MitekUsers */
export const getAllMitekUsersService = async (params?: GetAllMitekUsersParamsTypes)
  : Promise<APIPaginationResponse<MitekUsersTypes[]>> => {
  const res = await axiosInstance.get('mitek-users', { params });
  return res.data;
};

export const getMitekUsersByIdService = async ({ id }: GetMitekUsersByIdParamsTypes)
  : Promise<MitekUsersTypes> => {
  const res = await axiosInstance.get(`mitek-users/${id}`);
  return res.data.data;
};

export const createMitekUsersService = async (params: CreateMitekUsersParamsTypes)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('mitek-users', params);
  return res.data.data;
};

export const deleteMitekUsersService = async (params: { ids: number[] })
  : Promise<unknown> => {
  const res = await axiosInstance.delete('mitek-users', { data: params });
  return res.data;
};
