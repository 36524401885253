/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import HeaderPage from 'common/components/HeaderPage';
import { searchSerialNumberService } from 'common/services/extends/serials';
import { formatPhoneVietnamese } from 'common/utils/functions';

const SearchSerials: React.FC = () => {
  const { t } = useTranslation();
  const [serialErr, setSerialErr] = useState(true);

  const { mutate: searchSerial, isLoading, data: serialData } = useMutation(['valid-lucky-draw'], searchSerialNumberService);
  const dataTable = useMemo(() => {
    if (serialData) {
      if (serialData.blackListSeriesNumber.length > 0) {
        return serialData.blackListSeriesNumber.map(((item) => ({
          id: item.id,
          serial: item.seriesNumber,
          modelNumber: item.modelNumber,
          customerName: item.customerName,
        })));
      }
      return serialData.submittedProducts.map((item) => ({
        serial: item.productData.seriesNumber,
        productName: item.productData.productName,
        model: item.productData.modelName,
        customerName: item.billData.fullName,
        phone: item.billData.phone
      }));
    }
    return [];
  }, [serialData]);
  const isBlackList = useMemo(() => (serialData
    ? serialData?.blackListSeriesNumber.length > 0 : false), [serialData]);
  const columns: ColumnsType<any> = [
    {
      title: t('searchSearials.serial'),
      dataIndex: 'serial',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.serial}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.productName'),
      dataIndex: 'productName',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.productName}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.model'),
      dataIndex: 'model',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.model}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.customerName'),
      dataIndex: 'customerName',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.customerName}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.phone'),
      dataIndex: 'phone',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {formatPhoneVietnamese(data.phone)}
        </Typography.Text>
      ),
    },
  ];
  const blackListColumn: ColumnsType<any> = [
    {
      title: t('searchSearials.serial'),
      dataIndex: 'serial',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.serial}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.customerName'),
      dataIndex: 'customerName',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.customerName}
        </Typography.Text>
      ),
    },
    {
      title: t('searchSearials.modelNumber'),
      dataIndex: 'modelNumber',
      render: (_name: string, data: any) => (
        <Typography.Text>
          {data.modelNumber}
        </Typography.Text>
      ),
    },
  ];
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.searchSerials')}
      />
      <div className="t-mainlayout_wrapper">
        <Input.Search
          className="u-mt-8"
          placeholder={t('searchSearials.placeholder')}
          onChange={(e) => setSerialErr(/^\d{8}$/.test(e.currentTarget.value))}
          onSearch={(e) => searchSerial(e)}
          size="large"
          pattern="/^\d{8}$/"
        />
        {!serialErr && (
          <span
            className="a-input_errorMessage"
          >
            {t('searchSearials.invalid')}
          </span>
        )}
        <Table
          loading={isLoading}
          className="u-mt-16"
          dataSource={dataTable}
          showSorterTooltip={false}
          columns={isBlackList ? blackListColumn : columns}
        />
      </div>
    </>
  );
};

export default SearchSerials;
