import {
  BuyCardForTelCoParams,
  ExportDataTypes,
  GetPrizeByIdParamsTypes,
  LuckyDrawMatchTypes,
  LuckyDrawMatchValidRequest,
  LuckyDrawPrize,
  LuckyDrawRound,
  UpdateProfileParamsTypes
} from './type';

import axiosInstance from 'common/services/common/instance';

export const getAllLuckyDrawMatchService = async (params?: BaseFilterParams & {
  rounds?: string[]
}):
  Promise<APIPaginationResponse<LuckyDrawMatchTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-matches', { params });
  return res.data;
};

export const getAllFirstPrizeService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<LuckyDrawMatchTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-matches/1st', { params });
  return res.data;
};

export const getAllSecondPrizeService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<LuckyDrawMatchTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-matches/2nd', { params });
  return res.data;
};

export const getAllThirdPrizeService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<LuckyDrawMatchTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-matches/3rd', { params });
  return res.data;
};

export const getAllFourthPrizeService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<LuckyDrawMatchTypes[]>> => {
  const res = await axiosInstance.get('lucky-draw-matches/4th', { params });
  return res.data;
};

export const getPrizeByIdService = async ({ id }: GetPrizeByIdParamsTypes):
  Promise<LuckyDrawMatchTypes> => {
  const res = await axiosInstance.get(`lucky-draw-matches/${id}/detail`);
  return res.data.data;
};

export const exportFirstPrizeService = async ():
  Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('lucky-draw-matches/1st/export');
  return res.data;
};

export const exportSecondPrizeService = async ():
  Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('lucky-draw-matches/2nd/export');
  return res.data;
};

export const exportThirdPrizeService = async ():
  Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('lucky-draw-matches/3rd/export');
  return res.data;
};

export const exportFourthPrizeService = async ():
  Promise<ExportDataTypes> => {
  const res = await axiosInstance.get('lucky-draw-matches/4th/export');
  return res.data;
};

export const publishPrizeService = async (
  ids?: number[],
  prize?: LuckyDrawPrize,
  round?: LuckyDrawRound
):
  Promise<void> => {
  await axiosInstance.post('lucky-draw-matches/publish', { ids, prize, round });
};

export const updateProfileByIdService = async (id: number, params: UpdateProfileParamsTypes)
  : Promise<void> => {
  await axiosInstance.post(`lucky-draw-matches/${id}/update-profile`, params);
};

export const luckyDrawMatchExportService = async (params?: BaseFilterParams & {
  rounds?: string[]
})
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('lucky-draw-matches/export', undefined, { params });
  return res.data.data;
};

export const postLuckyDrawMatchValidService = async (params?: LuckyDrawMatchValidRequest)
  : Promise<void> => {
  await axiosInstance.post('lucky-draw-matches/valid', params);
};

export const postLuckyDrawMatchInvalidService = async (params?: LuckyDrawMatchValidRequest)
  : Promise<void> => {
  await axiosInstance.post('lucky-draw-matches/invalid', params);
};

export const resendSMSService = async (luckyDrawMatchId: number)
  : Promise<void> => {
  await axiosInstance.post('lucky-draw-matches/send-sms', { luckyDrawMatchId });
};

export const topupBuyCardService = async (luckyDrawMatchIds: number[])
  : Promise<void> => {
  await axiosInstance.post('topup/buy-card', { luckyDrawMatchIds });
};

export const topupFouthPrizeService = async (
  ids?: number[],
  round?: LuckyDrawRound
):
  Promise<void> => {
  await axiosInstance.post('lucky-draw-matches/topup-fourth-prize', { ids, round });
};

export const topupBuyCardForTelCoService = async (params: BuyCardForTelCoParams)
  : Promise<void> => {
  await axiosInstance.post('topup/buy-card-for-telco', params);
};
