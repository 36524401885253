import {
  Button,
  Col, Modal, Row, Typography, message
} from 'antd';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import Input from 'common/components/Input';
import {
  postLuckyDrawInvalidService,
  postLuckyDrawValidService
} from 'common/services/extends/luckyDraw';
import { LuckyDrawValidRequest } from 'common/services/extends/luckyDraw/type';

interface Props {
  isOpen: boolean;
  id?: number;
  handleClose(): void;
  type?: string;
}

interface RequestParams {
  note?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DetailModal: React.FC<Props> = ({
  handleClose, isOpen, id, type
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const idParams = Number(searchParams.get('id'));

  const mainMethod = useForm<RequestParams>({
    mode: 'onChange',
    defaultValues: {
      note: '',
    }
  });

  const { mutate: postLuckyDrawValidMutate, isLoading: validLoading } = useMutation(
    ['valid-lucky-draw'],
    (param: LuckyDrawValidRequest) => postLuckyDrawValidService(param),
    {
      onSuccess() {
        message.success(t('media.updateSuccess'));
        mainMethod.reset();
        handleClose();
        queryClient.invalidateQueries(['getSubmittedBillById', idParams]);
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );

  const { mutate: postLuckyDrawInvalidMutate, isLoading: invalidLoading } = useMutation(
    ['invalid-lucky-draw'],
    (param: LuckyDrawValidRequest) => postLuckyDrawInvalidService(param),
    {
      onSuccess() {
        message.success(t('media.updateSuccess'));
        mainMethod.reset();
        handleClose();
        queryClient.invalidateQueries(['getSubmittedBillById', idParams]);
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: LuckyDrawValidRequest = {
      luckyDrawIds: [id || 0],
      note: mainData.note,
    };

    try {
      if (type === 'valid') {
        postLuckyDrawValidMutate(dataSubmit);
      } else {
        postLuckyDrawInvalidMutate(dataSubmit);
      }
    } catch (error: any) {
      message.error(t('message.updateError'));
    }
  };

  return (
    <Modal
      title={(
        <Typography.Title level={3} style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {type === 'valid' ? t('submitttedBill.validConfirm') : t('submitttedBill.invalidConfirm')}
        </Typography.Title>
      )}
      width="60vw"
      visible={isOpen}
      closable
      footer={null}
      onCancel={handleClose}
    >
      <div className="modal-container">
        <FormProvider {...mainMethod}>
          <Row
            gutter={16}
            className="u-mt-16"
          >
            <Col span={24}>
              <Typography.Text strong>
                {t('submitttedBill.note')}
              </Typography.Text>
              <Controller
                name="note"
                defaultValue=""
                render={({
                  field: { value: fieldValue, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    className="u-mt-8"
                    name="note"
                    value={fieldValue}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className="u-mt-16"
                type="primary"
                onClick={() => handleSubmit()}
                disabled={validLoading || invalidLoading}
              >
                {t('system.ok')}
              </Button>
            </Col>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default DetailModal;
