import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col, message, Row,
  Space, Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import React, {
  useEffect
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { createShopsService, getShopsByIdService, updateShopsService } from 'common/services/extends/shops';
import { CreateShopsParamsTypes } from 'common/services/extends/shops/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { shopsSchema } from 'common/utils/schemas';

type EditShopDataTypes = {
  name: string;
  address: string;
};

const EditShops: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  const mainMethod = useForm<EditShopDataTypes>({
    resolver: yupResolver(shopsSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      address: '',
    }
  });

  /* Hooks */
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* Queries */
  const { data: detailShop, isLoading: loadingDetail } = useQuery(
    ['getDetailShop', idParam],
    () => {
      if (idParam) {
        return getShopsByIdService({ id: idParam });
      }
      return undefined;
    }
  );

  const { mutate: updateShopByIdMutate, isLoading: updateLoading } = useMutation(
    'updateShopById',
    async (data: {
      id: number;
      params: CreateShopsParamsTypes
    }) => updateShopsService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createShopMutate, isLoading: createLoading } = useMutation(
    'createShop',
    createShopsService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.SHOPS_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  /* Variables */
  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: CreateShopsParamsTypes = {
      name: mainData.name,
      address: mainData.address,
    };

    try {
      if (idParam) {
        updateShopByIdMutate({
          id: idParam,
          params: dataSubmit
        });
      } else {
        createShopMutate(dataSubmit);
      }
    } catch (error: any) {
      message.error(idParam ? t('message.updateError') : t('message.createError'));
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailShop) {
      mainMethod.reset({
        name: detailShop.shopData.name,
        address: detailShop.shopData.address,
      });
    } else {
      mainMethod.reset();
    }
  }, [detailShop, mainMethod]);

  /* Render */
  return (
    <div className="p-editShop">
      <HeaderPage
        fixed
        title={idParam ? t('shops.edit') : `${t('shops.create')}`}
        rightHeader={(
          <Button
            type="primary"
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            onClick={() => handleSubmit()}
            loading={updateLoading || createLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            {loadingDetail ? <Spin className="center-absolute" size="large" spinning />
              : (
                <Card
                  type="inner"
                >
                  <FormProvider {...mainMethod}>
                    <Space direction="vertical" size={12} style={{ width: '100%' }}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('system.name')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="name"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input u-mt-12">
                            <Typography.Text strong>
                              {t('footer.address')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name="address"
                              control={mainMethod.control}
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </FormProvider>
                </Card>
              )}
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={detailShop ? dayjs(detailShop?.shopData.createdAt).fromNow() : ''}
              createdBy=""
              lastUpdated={detailShop ? dayjs(detailShop?.shopData.updatedAt).fromNow() : ''}
              lastUpdatedBy=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditShops;
