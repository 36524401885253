/* eslint-disable no-nested-ternary */
import { ExportOutlined, EyeOutlined, ShareAltOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Modal, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import ClearPrizeslModal from './clearPrizesModal';

import { useAppDispatch, useAppSelector } from 'app/store';
import { setSendSMSAllProcess } from 'app/systemSlice';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useRoundDropdown from 'common/hooks/useRoundDropdown';
import {
  getAllLuckyDrawMatchService,
  luckyDrawMatchExportService,
  publishPrizeService,
  topupBuyCardService,
  topupFouthPrizeService
} from 'common/services/extends/luckyDrawMatch';
import { LuckyDrawManagementDataTypes, LuckyDrawPrize, StatusTopup } from 'common/services/extends/luckyDrawMatch/type';
import { LuckyDrawRound } from 'common/services/extends/serials/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

export const renderReasonFailSMS = (reason: string) => {
  switch (reason) {
    case 'smsFail':
      return 'luckyDrawMatch.smsFail';
    case 'telcoIsNotSupport':
      return 'luckyDrawMatch.telcoIsNotSupport';
    case 'buyCardFail':
      return 'luckyDrawMatch.buyCardFail';
    case 'topupFail':
      return 'luckyDrawMatch.topupFail';
    case 'topupFailDueToPostPaid':
      return 'luckyDrawMatch.topupFailDueToPostPaid';
    case 'buyCardSuccess':
      return 'luckyDrawMatch.buyCardSuccess';
    case 'smsSuccess':
      return 'luckyDrawMatch.smsSuccess';
    case 'buyCardSuccess_300000':
      return 'luckyDrawMatch.buyCardSuccess_300000';
    case 'buyCardSuccess_200000':
    case 'buyCardSuccess_20000':
      return 'luckyDrawMatch.buyCardSuccess_200000';
    case 'buyCardSuccess_100000':
    case 'buyCardSuccess_10000':
      return 'luckyDrawMatch.buyCardSuccess_100000';
    case 'buyCardFail_300000':
      return 'luckyDrawMatch.buyCardFail_300000';
    case 'buyCardFail_200000':
    case 'buyCardFail_20000':
      return 'luckyDrawMatch.buyCardFail_200000';
    case 'buyCardFail_100000':
    case 'buyCardFail_10000':
      return 'luckyDrawMatch.buyCardFail_100000';
    case 'buyCardFailTransactionIsExisted':
      return 'luckyDrawMatch.buyCardFailTransactionIsExisted';
    case 'buyCardFailParamsInvalid':
      return 'luckyDrawMatch.buyCardFailParamsInvalid';
    case 'buyCardFailIPNotAllow':
      return 'luckyDrawMatch.buyCardFailIPNotAllow';
    case 'topupFailPhoneInactive':
      return 'luckyDrawMatch.topupFailPhoneInactive';
    case 'topupFailPhoneLocked':
      return 'luckyDrawMatch.topupFailPhoneLocked';
    case 'topupFailPhoneLockOneWay':
      return 'luckyDrawMatch.topupFailPhoneLockOneWay';
    case 'topupFailCannotAddMoney':
      return 'luckyDrawMatch.topupFailCannotAddMoney';
    case 'topupFailOutOfBalance':
      return 'luckyDrawMatch.topupFailOutOfBalance';
    case 'topupFailAmountNotAvailable':
      return 'luckyDrawMatch.topupFailAmountNotAvailable';
    case 'topupFailTransactionIsExisted':
      return 'luckyDrawMatch.topupFailTransactionIsExisted';
    case 'topupFailParamsInvalid':
      return 'luckyDrawMatch.topupFailParamsInvalid';
    case 'topupFailIPNotAllow':
      return 'luckyDrawMatch.topupFailIPNotAllow';
    case 'topupFailServerBusy':
      return 'luckyDrawMatch.topupFailServerBusy';
    case 'topupFailDueToPostPaidEzPay':
      return 'luckyDrawMatch.topupFailDueToPostPaidEzPay';
    case 'topupSuccess':
      return 'luckyDrawMatch.topupSuccess';
    default:
      return '';
  }
};

const LuckyDrawMatchManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate, roleOther
}) => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');
  const navigate = useNavigate();

  /* Selectors */
  const {
    defaultPageSize, advancedFilter, currentRoundMatch, sendSMSAllProcess
  } = useAppSelector((state) => state.system);
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const roundDropdownData = useRoundDropdown(roleOther);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTopupPrize, setIsOpenTopupPrize] = useState<boolean>(false);
  const [roundFilter, setRoundFilter] = useState<string | undefined>(currentRoundMatch);
  const [sendSmsAllLoading, setSendSmsAllLoading] = useState<boolean>(false);

  /* SendSMS modal */
  const sendSMSSchema = yup.object().shape({
    prize: yup.string().required('Vui lòng chọn'),
    round: yup.string().required('Vui lòng chọn'),
  });

  const sendTopupPrizeSchema = yup.object().shape({
    round: yup.string().required('Vui lòng chọn'),
  });

  const mainMethod = useForm<{
    prize?: LuckyDrawPrize,
    round?: LuckyDrawRound
  }>({
    resolver: yupResolver(sendSMSSchema),
    mode: 'onChange',
  });

  const method = useForm<{
    round?: LuckyDrawRound
  }>({
    resolver: yupResolver(sendTopupPrizeSchema),
    mode: 'onChange',
  });

  /* Queries */
  const { data: luckyDrawMatchData, isLoading } = useQuery(
    ['getAllLuckyDrawMatch', keyword, currentPage, currentView, selectedFilterList, roundFilter],
    () => getAllLuckyDrawMatchService({
      keyword, page: currentPage, limit: currentView, rounds: roundFilter ? roundFilter === 'all' ? ['round1', 'round2', 'round3'] : [roundFilter] : undefined, ...returnParamsFilter
    }),
    {
      enabled: !!currentPage,
      onSettled: () => {
        dispatch(setSendSMSAllProcess('none'));
      }
    }
  );

  const { mutate: publishMutate } = useMutation(
    ['publishPrize'],
    (data: {
      ids?: number[],
      prize?: LuckyDrawPrize,
      round?: LuckyDrawRound
    }) => publishPrizeService(data.ids, data.prize),
    {
      onError: () => {
        setSendSmsAllLoading(false);
        message.error(t('message.publishFail'));
      }
    }
  );

  const { mutate: buycardMutate, isLoading: buyCardLoading } = useMutation(
    ['buycard'],
    topupBuyCardService,
    {
      onSuccess: () => {
        message.success(t('message.buyCardSuccess'));
      },
      onError: () => {
        message.error(t('message.buyCardFail'));
      }
    }
  );

  const { mutate: topupFouthPrizeMutate, isLoading: topupFouthPrizeLoading } = useMutation(
    ['topupFouthPrize'],
    (data: {
      ids?: number[],
      round?: LuckyDrawRound
    }) => topupFouthPrizeService(data.ids, data.round),
    {
      onSuccess: () => {
        message.success(t('message.topupFourthPrizeSuccess'));
      },
      onError: () => {
        message.error(t('message.topupFourthPrizeFail'));
      }
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['luckyDrawMatch-export'],
    () => luckyDrawMatchExportService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter, rounds: roundFilter === 'all' || !roundFilter ? ['round1', 'round2', 'round3'] : [roundFilter]
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );

  const luckyDrawPrizeDummy = [
    {
      label: t('submitttedBill.firstPrize'),
      value: '1st',
    },
    {
      label: t('submitttedBill.secondPrize'),
      value: '2nd',
    },
    {
      label: t('submitttedBill.thirdPrize'),
      value: '3rd',
    },
    {
      label: t('submitttedBill.fourthPrize'),
      value: '4th',
    },
  ];
  const roundDummy = [
    { label: t('luckyDrawMatch.roundOne'), value: 'round1' },
    { label: t('luckyDrawMatch.roundTwo'), value: 'round2' },
    { label: t('luckyDrawMatch.roundThree'), value: 'round3' }
  ];

  const renderProfileStatus = (status?: number) => {
    switch (status) {
      case 1:
        return t('luckyDrawMatch.processing');
      case 7:
        return t('luckyDrawMatch.valid');
      case 9:
        return t('luckyDrawMatch.invalid');
      default:
        return t('luckyDrawMatch.notyetProfile');
    }
  };

  const renderTopupStatus = (status?: StatusTopup | null) => {
    switch (status) {
      case 'fail':
        return t('luckyDrawMatch.failTopup');
      case 'success':
        return t('luckyDrawMatch.successTopup');
      case 'processing':
        return t('luckyDrawMatch.processingTopup');
      case 'postpaid':
        return t('luckyDrawMatch.postpaidTopup');
      default:
        return '';
    }
  };

  const handleLuckyDrawPrize = (prize: LuckyDrawPrize): string => {
    switch (prize) {
      case '1st':
        return t('sidebar.firstPrize');
      case '2nd':
        return t('sidebar.secondPrize');
      case '3rd':
        return t('sidebar.thirdPrize');
      case '4th':
        return t('sidebar.fourthPrize');
      default:
        return '';
    }
  };

  /* Variables */
  const columns: ColumnsType<LuckyDrawManagementDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 60,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'billName',
      key: 'billName',
      width: 120,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.billName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.telco'),
      dataIndex: 'telco',
      key: 'telco',
      width: 160,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.telco}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.prizeType'),
      dataIndex: 'prizeType',
      key: 'prizeType',
      width: 120,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {handleLuckyDrawPrize(data.prize)}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.luckyCode'),
      dataIndex: 'luckyCode',
      key: 'luckyCode',
      width: 120,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.luckyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.shopName'),
      dataIndex: 'shopName',
      key: 'shopName',
      width: 120,
      render: (_: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.shopName}
        </Typography.Text>
      )
    },
    {
      title: t('system.joinDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (
        data.status === 1 ? t('luckyDrawMatch.pending') : data.status === 7
          ? t('luckyDrawMatch.delivered') : t('luckyDrawMatch.failSMS')
      ),
    },
    {
      title: t('luckyDrawMatch.reasonFailSMS'),
      dataIndex: 'reasonFailSMS',
      key: 'reasonFailSMS',
      width: 150,
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (data.status === 7 ? t('luckyDrawMatch.successSendSMS') : (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {t(renderReasonFailSMS(data.reasonFailSMS || ''))}
        </Typography.Text>
      )),
    },
    {
      title: t('luckyDrawMatch.topupStatus'),
      dataIndex: 'topupStatus',
      key: 'topupStatus',
      width: 120,
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {t(renderTopupStatus(data.topupStatus))}
        </Typography.Text>
      ),
    },
    {
      title: t('luckyDrawMatch.profileStatus'),
      dataIndex: 'profileStatus',
      key: 'profileStatus',
      width: 150,
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (data.prize === '4th' ? null : (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {renderProfileStatus(data.profileStatus)}
        </Typography.Text>
      )),
    },
    {
      title: t('luckyDrawMatch.note'),
      dataIndex: 'note',
      key: 'note',
      width: 120,
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.profileStatusNote}
        </Typography.Text>
      ),
    },
    {
      title: t('system.publishedAt'),
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      width: 120,
      render: (_: string, data: any) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatDateTime(data.publishedAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (_name: string, data: LuckyDrawManagementDataTypes) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.LUCKY_DRAW_MATCH_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('luckyDrawMatch', advancedFilter),
    [advancedFilter]
  );

  const luckyDrawMatchFilteredData:
    LuckyDrawManagementDataTypes[] = useMemo(() => (luckyDrawMatchData?.data?.map(
      (val) => ({
        id: val.mainData.id,
        billName: val.bill?.fullName || '',
        phone: formatPhoneVietnamese(val.bill?.phone) || '',
        telco: val.bill?.telco || '',
        prize: val.mainData.prize || '1st',
        luckyCode: val.mainData?.luckyCode || '',
        shopName: val.bill.shopName || '',
        status: val.mainData?.status || 1,
        publishedAt: val.mainData.publishedAt || '',
        createdAt: val.mainData.createdAt || '',
        reasonFailSMS: val.mainData.reasonFailSMS ? val.mainData.reasonFailSMS[val.mainData.reasonFailSMS.length - 1] : '',
        profileStatus: val.profile?.status,
        profileStatusNote: val.profile?.note,
        topupStatus: val.mainData.statusTopup,
      })
    ) || []), [luckyDrawMatchData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const handleBuckActions = (
    publishAllRole: boolean,
    topFourthPrizeRole: boolean,
    buycardRole: boolean
  ) => ([
    ...publishAllRole ? [{ label: t('luckyDrawMatch.publish'), value: 'publish' }] : [],
    ...topFourthPrizeRole ? [{ label: t('luckyDrawMatch.topupFourthPrize'), value: 'topup' }] : [],
    ...buycardRole ? [{ label: t('luckyDrawMatch.buyCard'), value: 'buycard' }] : []
  ]);

  const onSubmitSendSMSAll = (data: {
    prize?: LuckyDrawPrize,
    round?: LuckyDrawRound
  }) => {
    setSendSmsAllLoading(true);
    dispatch(setSendSMSAllProcess('processing'));
    publishMutate({ ids: undefined, prize: data.prize, round: data.round });
  };

  const onSubmitTopupFourthPrize = (data: {
    round?: LuckyDrawRound
  }) => {
    topupFouthPrizeMutate({ ids: undefined, round: data.round });
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendSMSAllProcess === 'success') {
      setSendSmsAllLoading(false);
      queryClient.invalidateQueries('getAllLuckyDrawMatch');
    }
  }, [queryClient, sendSMSAllProcess]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.luckyDraw')}
        rightHeader={(
          <Space>
            {roleOther.includes(roles.LUCKY_DRAW_MATCH_TOPUP_FOURTH_PRIZE) && (
              <Button type="primary" onClick={() => setIsOpenTopupPrize(true)}>
                <ShareAltOutlined />
                {t('luckyDrawMatch.topupFourthPrize')}
              </Button>
            )}
            {roleOther.includes(roles.LUCKY_DRAW_MATCH_PUBLISHALL) && (
              <Button type="primary" onClick={() => setIsOpen(true)}>
                <ShareAltOutlined />
                {t('luckyDrawMatch.publishAll')}
              </Button>
            )}
            {(rolesUser.includes('*') || getPermission(roleOther, roles.LUCKY_DRAW_MATCH_CLEARPRIZE))
              && (
                <Button
                  style={{
                    backgroundColor: '#ee5e52',
                    borderRadius: '4px'
                  }}
                  onClick={() => setIsOpenModal(true)}
                >
                  <Typography.Text
                    style={{ color: 'white' }}
                  >
                    {t('luckyDrawMatch.deleteLuckyDraw')}
                  </Typography.Text>
                </Button>
              )}
            <Button
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.LUCKY_DRAW_MATCH_EXPORT) || exportLoading}
              onClick={() => exportMutate()}
            >
              <ExportOutlined />
              {t('system.exportReport')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate,
            roleDelete: roleOther.includes(roles.LUCKY_DRAW_MATCH_PUBLISHALL),
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'phone', 'billName', 'prizeType', 'luckyCode',
              'status', 'reasonFailSMS', 'topupStatus', 'profileStatus', 'note', 'action'],
            columns,
            pageData: luckyDrawMatchFilteredData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: luckyDrawMatchData?.meta.total,
            filterFields,
            noDeleteLanguage: true,
            noBaseCol: true,
          }}
          statusDataTable={{
            changeStatusLoading: sendSmsAllLoading || topupFouthPrizeLoading || buyCardLoading,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
            extendedFilter: <DropdownElement
              noMargin
              size="middle"
              options={roundDropdownData}
              placeholder={`${t('system.select')} ${t('luckyDrawMatch.round')}`}
              locale="vi"
              value={roundFilter}
              onChange={(e) => setRoundFilter(e)}
            />
          }}
          buckActions={handleBuckActions(
            roleOther.includes(roles.LUCKY_DRAW_MATCH_PUBLISHALL),
            roleOther.includes(roles.LUCKY_DRAW_MATCH_TOPUP_FOURTH_PRIZE),
            roleOther.includes(roles.TOPUP_BUYCARD)
          )}
          handleChangeBulkAction={(action, selected) => {
            if (action === 'publish') {
              publishMutate({ ids: selected.map((item) => item.id) });
            }
            if (action === 'topup') {
              topupFouthPrizeMutate({ ids: selected.map((item) => item.id) });
            }
            if (action === 'buycard') {
              buycardMutate(selected.map((item) => item.id));
            }
          }}
        />
      </div>
      <ClearPrizeslModal
        isOpen={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
      />
      <Modal
        bodyStyle={{ paddingTop: '20px' }}
        open={isOpen}
        footer={(
          <Button
            type="primary"
            loading={sendSmsAllLoading}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteLanguageModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.confirmSendSMSAll'),
                onOk: mainMethod.handleSubmit(onSubmitSendSMSAll),
              });
            }}
          >
            {t('luckyDrawMatch.publish')}
          </Button>
        )}
        title={t('luckyDrawMatch.prize')}
        onCancel={() => setIsOpen(false)}
      >
        <FormProvider {...mainMethod}>
          <Controller
            name="prize"
            control={mainMethod.control}
            render={({ field, fieldState: { error } }) => (
              <DropdownElement
                options={luckyDrawPrizeDummy}
                placeholder={`${t('system.select')} ${t('luckyDrawMatch.prize')}`}
                locale="vi"
                value={field.value}
                error={error?.message}
                isShowSearch
                noMargin
                size="large"
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="round"
            control={mainMethod.control}
            render={({ field, fieldState: { error } }) => (
              <DropdownElement
                options={roundDummy}
                placeholder={`${t('system.select')} ${t('luckyDrawMatch.round')}`}
                locale="vi"
                value={field.value}
                error={error?.message}
                isShowSearch
                size="large"
                onChange={field.onChange}
              />
            )}
          />
        </FormProvider>
      </Modal>
      <Modal
        bodyStyle={{ paddingTop: '20px' }}
        open={isOpenTopupPrize}
        footer={(
          <Button
            type="primary"
            loading={topupFouthPrizeLoading}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteLanguageModal',
                autoFocusButton: 'cancel',
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                title: t('message.confirmTopupAll'),
                onOk: method.handleSubmit(onSubmitTopupFourthPrize),
              });
            }}
          >
            {t('luckyDrawMatch.topupFourthPrize')}
          </Button>
        )}
        title={t('luckyDrawMatch.round')}
        onCancel={() => setIsOpenTopupPrize(false)}
      >
        <FormProvider {...method}>
          <Controller
            name="round"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <DropdownElement
                options={roundDummy}
                placeholder={`${t('system.select')} ${t('luckyDrawMatch.round')}`}
                locale="vi"
                value={field.value}
                error={error?.message}
                isShowSearch
                size="large"
                onChange={field.onChange}
              />
            )}
          />
        </FormProvider>
      </Modal>
    </>
  );
};

export default LuckyDrawMatchManagement;
