/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EyeOutlined, SaveOutlined, CheckSquareFilled, EditFilled
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card, Col, DatePicker, Empty, Image, Modal, Row, Space, Spin, Table, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DropdownModel from './DropdownModel';
import DetailModal from './detailModal';
import useAddressOptions from './useAddressOptions';

import { useAppSelector } from 'app/store';
import pdf from 'common/assets/images/pdf.png';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import { SelectMultipleFile } from 'common/components/SelectFile';
import { resendLuckyCodeService } from 'common/services/extends/luckyDraw';
import { getSubmittedBillByIdService, updateSubmittedBillService } from 'common/services/extends/submittedBill';
import {
  LuckyDrawStatus, ResendLuckyDrawHistoriesTypes, SubmittedBillProductTypes, ValidHistoriesTypes
} from 'common/services/extends/submittedBill/types';
import { formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import { editSubmittedBillSchema } from 'common/utils/schemas';
import roles from 'configs/roles';

const { Title } = Typography;

type FormType = {
  phone: string
  fullName: string
  email?: string
  provinceCode: string
  districtCode: string
  wardCode?: string
  address?: string
  buyDate: string
  shopId?: number | string;
  shopName?: string
  shopAddress?: string
  submittedImages: {
    mediaId?: number
    path: string
  }[],
  products: SubmittedBillProductTypes[];
};

const EditSubmittedBill: React.FC<ActiveRoles> = ({ roleUpdate, roleOther }) => {
  /* Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const [activeIdx, setActiveIdx] = useState(-1);
  const [typeAction, setTypeAction] = useState('');
  const [validHistoryId, setValidHistoryId] = useState<number | undefined>();
  const [editModel, setEditModel] = useState<{
    billId: number, productId: number, modelId: number, seriesNumber: string;
  } | undefined>();

  const idParams = Number(searchParams.get('id'));
  const mainMethod = useForm<FormType>({
    resolver: yupResolver(editSubmittedBillSchema),
    mode: 'onChange',
    defaultValues: {
      submittedImages: undefined
    }
  });

  const provinceWatch = useWatch({ name: 'provinceCode', control: mainMethod.control });
  const districtWatch = useWatch({ name: 'districtCode', control: mainMethod.control });
  const shopId = useWatch({ name: 'shopId', control: mainMethod.control });
  const {
    provinces, districts, wards, isLoadingProvinces, isLoadingDistricts, isLoadingWards
  } = useAddressOptions(provinceWatch, districtWatch);
  /* Queries */
  const { data: submittedBillByIdData, isLoading: submittedBillLoading } = useQuery(
    ['getSubmittedBillById', idParams],
    () => {
      if (idParams) {
        return getSubmittedBillByIdService({
          id: idParams,
        });
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['updateSubmittedBill'],
    updateSubmittedBillService,
    {
      onSuccess() {
        message.success(t('media.updateSuccess'));
        mainMethod.reset();
        queryClient.invalidateQueries(['getSubmittedBillById', idParams]);
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );
  const { mutate: resendMutate, isLoading: resendSMSLoading } = useMutation(
    ['resendSMSPrize'],
    resendLuckyCodeService,
    {
      onSuccess() {
        message.success(t('message.resendSMSSuccess'));
        queryClient.invalidateQueries(['getSubmittedBillById', idParams]);
      },
      onError: () => {
        message.error(t('message.resendSMSFail'));
      }
    }
  );

  const mappingFieldStatus = (status: LuckyDrawStatus) => {
    switch (status) {
      case 'valid':
        return t('system.valid');
      case 'invalid':
        return t('system.inValid');
      default:
        return t('submitttedBill.statusNew');
    }
  };

  const detectPrize = (prizeParams: string) => {
    if (prizeParams === '1st') return t('submitttedBill.firstPrize');
    if (prizeParams === '2nd') return t('submitttedBill.secondPrize');
    if (prizeParams === '3rd') return t('submitttedBill.thirdPrize');
    if (prizeParams === '4th') return t('submitttedBill.fourthPrize');
    return '';
  };

  const columns: ColumnsType<SubmittedBillProductTypes> = [
    {
      title: 'STT',
      key: 'id',
      dataIndex: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: SubmittedBillProductTypes, index) => (
        <Typography.Text>
          {index + 1}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.productName'),
      key: 'productName',
      dataIndex: 'productName',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Typography.Text>
          {data.productData.productName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.modelName'),
      key: 'modelName',
      dataIndex: 'modelName',
      width: 180,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Space>
          <Typography.Text>
            {data.productData.modelName}
          </Typography.Text>
          {(roleOther.includes(roles.SUBMITTED_BILL_UPDATE_MODEL) || rolesUser.includes('*')) && (
            <Button
              type="text"
              icon={<EditFilled />}
              onClick={() => idParams && setEditModel({
                billId: idParams,
                productId: data.productData.productId,
                modelId: data.productData.modelId,
                seriesNumber: data.productData.seriesNumber
              })}
            />
          )}
        </Space>
      ),
    },
    {
      title: t('submitttedBill.seriesNumber'),
      key: 'seriesNumber',
      dataIndex: 'seriesNumber',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Typography.Text>
          {data.productData.seriesNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.luckyDrawCode'),
      key: 'luckyDrawCode',
      dataIndex: 'luckyDrawCode',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Typography.Text>
          {data.luckyDrawData.code}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.winPrize'),
      dataIndex: 'winPrize',
      key: 'winPrize',
      width: 120,
      render: (_: string, data: SubmittedBillProductTypes) => (
        <div style={{ display: 'flex' }}>
          {data.luckyDrawData.matches.map((item) => detectPrize(item.prize)).join(', ')}
        </div>
      ),
    },
    {
      title: t('system.status'),
      key: 'status',
      dataIndex: 'status',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Typography.Text>
          {mappingFieldStatus(data.luckyDrawData.newStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.invalidType'),
      key: 'invalidType',
      dataIndex: 'invalidType',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Typography.Text>
          {data.luckyDrawData.note}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      dataIndex: 'action',
      width: 200,
      align: 'center',
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Space direction="vertical" align="center">
          <Space>
            {data.luckyDrawData.newStatus !== 'valid' && (
              <Button
                disabled={!roleOther.includes(roles.LUCKY_DRAW_VALID)}
                onClick={() => {
                  setActiveIdx(data.luckyDrawData.id);
                  setTypeAction('valid');
                }}
                type="primary"
              >
                {t('system.valid')}
              </Button>
            )}
            {data.luckyDrawData.newStatus !== 'invalid' && (
              <Button
                disabled={!roleOther.includes(roles.LUCKY_DRAW_INVALID)}
                onClick={() => {
                  setActiveIdx(data.luckyDrawData.id);
                  setTypeAction('');
                }}
                danger
              >
                {t('system.inValid')}
              </Button>
            )}
          </Space>
          {roleOther.includes(roles.LUCKY_DRAW_RESEND_CODE) && (
            <Button
              onClick={() => {
                resendMutate(String(data.luckyDrawData.id));
              }}
              type="dashed"
              loading={resendSMSLoading}
            >
              {t('submitttedBill.resendSMSPrize')}
            </Button>
          )}
          {data.luckyDrawData.matches.length > 0 && (
            <Button
              onClick={() => {
                navigate(`/lucky-draw-match-detail?id=${data.luckyDrawData.matches[0].id}`);
              }}
              type="primary"
              icon={<EyeOutlined />}
            >
              {t('submitttedBill.gotoMatchDetail')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const updateColumn: ColumnsType<SubmittedBillProductTypes> = [
    {
      title: t('submitttedBill.validHistory'),
      key: 'validHistory',
      dataIndex: 'validHistory',
      align: 'center',
      width: 120,
      render: (_name: string, data: SubmittedBillProductTypes) => (
        <Space style={{ justifyContent: 'center', width: '100%' }}>
          <Button
            icon={<EyeOutlined />}
            onClick={() => setValidHistoryId(data.luckyDrawData.id)}
          />
        </Space>
      ),
    }
  ];

  const validHistoryColumn: ColumnsType<ValidHistoriesTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.from'),
      key: 'from',
      width: 120,
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {mappingFieldStatus(data.fromStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.to'),
      key: 'to',
      width: 120,
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {mappingFieldStatus(data.toStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.user'),
      key: 'user',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.user.name}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.doAt'),
      key: 'user',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'email',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.user.email}
        </Typography.Text>
      ),
    },
  ];
  const updateModelHistoryColumn: ColumnsType<ValidHistoriesTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.from'),
      key: 'from',
      width: 160,
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.invalidTypeFrom}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.to'),
      key: 'to',
      width: 160,
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.invalidTypeTo}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.user'),
      key: 'user',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.user.name}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.doAt'),
      key: 'user',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'email',
      render: (_name: string, data: ValidHistoriesTypes) => (
        <Typography.Text>
          {data.user.email}
        </Typography.Text>
      ),
    },
  ];
  const resendHistoryColumn: ColumnsType<ResendLuckyDrawHistoriesTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: ResendLuckyDrawHistoriesTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.user'),
      key: 'user',
      render: (_name: string, data: ResendLuckyDrawHistoriesTypes) => (
        <Typography.Text>
          {data.user.name}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.doAt'),
      key: 'user',
      render: (_name: string, data: ResendLuckyDrawHistoriesTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'email',
      render: (_name: string, data: ResendLuckyDrawHistoriesTypes) => (
        <Typography.Text>
          {data.user.email}
        </Typography.Text>
      ),
    },
  ];

  const submittedBillData = useMemo(() => ({
    name: submittedBillByIdData?.billData.fullName,
    phone: formatPhoneVietnamese(submittedBillByIdData?.billData.phone),
    email: submittedBillByIdData?.billData.email,
    address: submittedBillByIdData?.billData.address,
    wardName: submittedBillByIdData?.billData.wardName,
    districtName: submittedBillByIdData?.billData.districtName,
    provinceName: submittedBillByIdData?.billData.provinceName,
    buyDate: submittedBillByIdData?.billData.buyDate,
    shopAddress: submittedBillByIdData?.billData.shopAddress,
    shopName: submittedBillByIdData?.billData.shopName,
    createdAt: submittedBillByIdData?.billData.createdAt || '',
    updatedAt: submittedBillByIdData?.billData.updatedAt || '',
  }), [submittedBillByIdData]);

  const submittedBillTableData: SubmittedBillProductTypes[] = useMemo(
    () => submittedBillByIdData?.products || [],
    [submittedBillByIdData]
  );

  const validHistoryData: ValidHistoriesTypes[] = useMemo(() => {
    const found = submittedBillByIdData?.products.find(
      (item) => item.luckyDrawData.id === validHistoryId
    );
    if (!found) {
      return [];
    }
    return found.luckyDrawData.validHistories;
  }, [submittedBillByIdData, validHistoryId]);
  const updateModelHistoryData: ValidHistoriesTypes[] = useMemo(() => {
    const found = submittedBillByIdData?.products.find(
      (item) => item.luckyDrawData.id === validHistoryId
    );
    if (!found) {
      return [];
    }
    return found.productData.modelHistories.map((item) => ({
      id: item.id,
      user: {
        name: item.updateBy.name,
        email: item.updateBy.email
      },
      invalidTypeFrom: item.oldModelName,
      invalidTypeTo: item.newModelName,
      fromStatus: 'new',
      toStatus: 'invalid',
      createdAt: item.createdAt
    }));
  }, [submittedBillByIdData, validHistoryId]);

  const resendHistoryData: ResendLuckyDrawHistoriesTypes[] = useMemo(() => {
    const found = submittedBillByIdData?.products.find(
      (item) => item.luckyDrawData.id === validHistoryId
    );
    if (!found) {
      return [];
    }
    return found.luckyDrawData.resendLuckyDrawHistories;
  }, [submittedBillByIdData, validHistoryId]);

  const activeId = useMemo(() => (activeIdx !== -1
    ? (submittedBillByIdData?.products.find((i) => i.luckyDrawData.id === activeIdx)
      ?.luckyDrawData.id
      || undefined) : undefined), [activeIdx, submittedBillByIdData]);
  const onSubmit = (data: FormType) => {
    updateMutate({
      id: idParams,
      phone: data.phone,
      fullName: data.fullName,
      email: data.email,
      provinceCode: data.provinceCode,
      districtCode: data.districtCode,
      wardCode: data.wardCode,
      address: data.address,
      buyDate: dayjs(data.buyDate).format('YYYY-MM-DD'),
      shopId: data.shopId === 'other' ? undefined : data.shopId,
      shopName: data.shopName || undefined,
      submittedImages: data.submittedImages.map((item) => ({ id: item.mediaId, image: item.path }))
    });
  };

  useEffect(() => {
    mainMethod.reset({
      phone: formatPhoneVietnamese(submittedBillByIdData?.billData.phone),
      fullName: submittedBillByIdData?.billData.fullName || '',
      email: submittedBillByIdData?.billData.email || '',
      shopId: submittedBillByIdData?.billData.shopId || 'other',
      shopName: !submittedBillByIdData?.billData.shopId
        ? (submittedBillByIdData?.billData.shopName || undefined) : undefined,
      shopAddress: submittedBillByIdData?.billData.shopAddress || undefined,
      buyDate: submittedBillByIdData?.billData.buyDate || undefined,
      wardCode: submittedBillByIdData?.billData.wardCode,
      districtCode: submittedBillByIdData?.billData.districtCode || '',
      provinceCode: submittedBillByIdData?.billData.provinceCode || '',
      address: submittedBillByIdData?.billData.address || '',
      submittedImages: submittedBillByIdData?.images.map((item) => ({
        mediaId: item.id,
        path: item.url,
      })) || [],
    });
  }, [idParams, mainMethod, submittedBillByIdData]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.submittedBillDetail')}
        rightHeader={roleUpdate ? (
          <Button
            type="primary"
            loading={updateLoading}
            disabled={(!idParams || !roleUpdate)}
            onClick={mainMethod.handleSubmit(onSubmit)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        ) : undefined}
      />
      <Spin spinning={submittedBillLoading}>
        {submittedBillByIdData ? (
          <FormProvider {...mainMethod}>
            <div className="t-mainlayout_wrapper">
              <div className="modal-container">
                {/* Thông tin khách hàng */}
                <Card title={<Title level={3}>{t('submitttedBill.customerInfo')}</Title>} style={{ marginTop: '16px' }}>
                  <Row gutter={16}>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.name')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="fullName"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="fullName"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.name}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.phone')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="phone"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="phone"
                              readOnly
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.phone}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.email')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="email"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="email"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.email}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.provinceName')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="provinceCode"
                          control={mainMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <DropdownElement
                              options={provinces}
                              placeholder={`${t('system.select')} ${t('system.province')}`}
                              locale="vi"
                              loading={isLoadingProvinces}
                              value={field.value || undefined}
                              isShowSearch
                              size="large"
                              error={error?.message}
                              onChange={(e) => {
                                field.onChange(e);
                                mainMethod.setValue('districtCode', '');
                                mainMethod.setValue('wardCode', '');
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.provinceName}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.districtName')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="districtCode"
                          control={mainMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <DropdownElement
                              options={districts}
                              placeholder={`${t('system.select')} ${t('system.district')}`}
                              locale="vi"
                              loading={isLoadingDistricts}
                              error={error?.message}
                              value={field.value}
                              isShowSearch
                              size="large"
                              onChange={(e) => {
                                field.onChange(e);
                                mainMethod.setValue('wardCode', '');
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.districtName}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.wardName')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="wardCode"
                          control={mainMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <DropdownElement
                              options={wards}
                              placeholder={`${t('system.select')} ${t('system.ward')}`}
                              locale="vi"
                              loading={isLoadingWards}
                              value={field.value}
                              error={error?.message}
                              isShowSearch
                              size="large"
                              onChange={field.onChange}
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.wardName}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.address')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="address"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="address"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.address}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.buyDate')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="buyDate"
                          control={mainMethod.control}
                          render={({ field }) => (
                            <DatePicker
                              size="large"
                              className="u-mt-8"
                              value={dayjs(field.value)}
                              onChange={field.onChange}
                              format="DD/MM/YYYY"
                              defaultValue={dayjs()}
                              showTime
                              style={{ width: '100%' }}
                              allowClear={false}
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {dayjs(submittedBillData.buyDate).format('DD/MM/YYYY')}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('submitttedBill.shop')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Controller
                          name="shopId"
                          control={mainMethod.control}
                          render={({ field }) => (
                            <DropdownElement
                              type="shop"
                              placeholder={`${t('system.select')} ${t('system.shop')}`}
                              locale="vi"
                              value={field.value}
                              isShowSearch
                              hasOther
                              size="large"
                              onChange={field.onChange}
                            />
                          )}
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {submittedBillData.shopName}
                        </Typography.Text>
                      )}
                    </Col>
                    {shopId === 'other' && roleUpdate && (
                      <Col span={8} className="u-mt-8">
                        <Typography.Text strong>
                          {t('submitttedBill.shopName')}
                          :
                        </Typography.Text>
                        <Controller
                          name="shopName"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="shopName"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                            />
                          )}
                        />
                      </Col>
                    )}
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.createdAt')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Input
                          className="u-mt-8"
                          value={formatDateTime(submittedBillData.createdAt)}
                          readOnly
                          size="large"
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {formatDateTime(submittedBillData.createdAt)}
                        </Typography.Text>
                      )}
                    </Col>
                    <Col span={8} className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.updatedAt')}
                        :
                      </Typography.Text>
                      {roleUpdate ? (
                        <Input
                          className="u-mt-8"
                          value={formatDateTime(submittedBillData.createdAt)}
                          readOnly
                          size="large"
                        />
                      ) : (
                        <Typography.Text className="u-ml-4">
                          {formatDateTime(submittedBillData.updatedAt)}
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Card>
                {/* Thông tin sản phẩm */}
                <Card title={<Title level={3}>{t('submitttedBill.productInfo')}</Title>} style={{ marginTop: '16px' }}>
                  <Table
                    loading={submittedBillLoading}
                    columns={roleOther.includes(roles.LUCKY_DRAW_VALID_HISTORY)
                      ? [...columns, ...updateColumn] : columns}
                    dataSource={submittedBillTableData}
                    pagination={false}
                    scroll={{ x: 986 }}
                  />
                </Card>
                {/* Hình ảnh hoá đơn */}
                <Card title={<Title level={3}>{t('submitttedBill.billImage')}</Title>} style={{ marginTop: '16px' }}>
                  {roleUpdate ? (
                    <Controller
                      name="submittedImages"
                      render={({
                        field: { value, onChange },
                      }) => (
                        <SelectMultipleFile
                          title={t('submitttedBill.billImage')}
                          value={value}
                          handleSelect={(data) => {
                            onChange(data);
                          }}
                          handleDelete={(data) => onChange(data)}
                        />
                      )}
                    />
                  ) : (
                    <Row gutter={16}>
                      <Col
                        span={24}
                        style={{
                          display: 'flex',
                          gap: '20px',
                          flexWrap: 'wrap'
                        }}
                      >
                        {submittedBillByIdData?.images.map((item, index) => (
                          <Image
                            key={`billImage-${index.toString()}`}
                            src={item.url.includes('.pdf') ? pdf : item.url}
                            preview={!item.url.includes('.pdf')}
                            style={{
                              width: '200px',
                              height: '200px',
                              objectFit: 'cover',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              if (item.url.includes('.pdf')) {
                                window.open(item.url, '_blank');
                              }
                            }}
                          />
                        ))}
                      </Col>
                    </Row>
                  )}
                </Card>
              </div>
            </div>
            <DetailModal
              isOpen={activeIdx !== -1 && !!activeId}
              id={activeId}
              handleClose={() => setActiveIdx(-1)}
              type={typeAction}
            />
            <Modal
              width="80vw"
              bodyStyle={{ paddingTop: '40px' }}
              open={!!validHistoryId}
              onCancel={() => setValidHistoryId(undefined)}
              footer={false}
            >
              <Card title={t('submitttedBill.updateStatusHistory')}>
                <Table
                  dataSource={validHistoryData}
                  pagination={false}
                  columns={validHistoryColumn}
                  scroll={{ x: 986 }}
                />
              </Card>
              <Card title={t('submitttedBill.updateModelHistory')} className="u-mt-16">
                <Table
                  dataSource={updateModelHistoryData}
                  pagination={false}
                  columns={updateModelHistoryColumn}
                  scroll={{ x: 986 }}
                />
              </Card>
              <Card title={t('submitttedBill.resendSMSHistory')} className="u-mt-16">
                <Table
                  dataSource={resendHistoryData}
                  pagination={false}
                  columns={resendHistoryColumn}
                  scroll={{ x: 986 }}
                />
              </Card>
            </Modal>
            {(roleOther.includes(roles.SUBMITTED_BILL_UPDATE_MODEL) || rolesUser.includes('*')) && (
              <DropdownModel
                isOpen={!!editModel}
                billId={idParams}
                oldModelId={editModel?.modelId || -1}
                productId={editModel?.productId || -1}
                seriesNumber={editModel?.seriesNumber || ''}
                handleUpdateModelSuccess={() => {
                  setEditModel(undefined);
                  queryClient.invalidateQueries(['getSubmittedBillById', idParams]);
                }}
                onClose={() => setEditModel(undefined)}
              />
            )}
          </FormProvider>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Spin>
    </>
  );
};

export default EditSubmittedBill;
