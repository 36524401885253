import {
  Card, Col, Image, Modal, Row, Table, Typography
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import Sider from 'antd/lib/layout/Sider';
import Layout from 'antd/lib/layout/layout';
import dayjs from 'dayjs';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';

import { LayoutContext } from './context';

import { useAppDispatch, useAppSelector } from 'app/store';
import { setSendSMSAllProcess, setSendSMSProcess, setTopupProcess } from 'app/systemSlice';
import IconLogo from 'common/assets/images/logoElec.png';
import pdfImage from 'common/assets/images/pdf.png';
import userImage from 'common/assets/images/user.png';
import Sidebar, { MenuItem } from 'common/components/Sidebar';
import { getLuckyDrawMatchService, getLuckyDrawsService } from 'common/services/luckyDrawMatches';
import { LuckyDrawPrize, LuckyDrawStatus } from 'common/services/luckyDrawMatches/types';
import getTokenWebSocketService from 'common/services/websocket';
import socket, { SocketInstanceType } from 'common/services/websocket/socket';
import { formatPhoneVietnamese } from 'common/utils/functions';

const { Title } = Typography;

type Props = {
  menus: MenuItem[];
};

export type CallCenterData = {
  phoneNumber: string;
};

type HistoryGiftItemType = {
  code: string;
  prizeType: LuckyDrawPrize;
  productName: string;
  modelName: string;
  seriesNumber: string;
};

type LuckyDrawBillData = {
  id?: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  wardName: string;
  districtName: string;
  provinceName: string;
  images: {
    url: string
  }[]
};

type ProductInfoData = {
  id: number;
  productName: string;
  modelName: string;
  seriesNumber: string;
  code: string;
  buyDate: string;
  shopName: string;
  newStatus: LuckyDrawStatus;
  note: string;
};

const Mainlayout: React.FC<Props> = ({ menus }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.profileData);
  const wsHostApi = useAppSelector((state) => state.system.initialData?.wsHost);
  // const roleOther = useAppSelector((state) => state.auth.roles);
  const { collapsed, setCollapsed } = useContext(LayoutContext);
  const [tokenWs, setTokenWs] = useState<string | undefined>(undefined);
  const [socketData, setSocketData] = useState<CallCenterData | undefined>(undefined);
  const wsInstance = useRef<SocketInstanceType | null>(null);
  const [currentPageHistoryTable, setCurrentPageHistoryTable] = useState(1);
  const [openDetail, setOpenDetail] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const handlePrizeType = useCallback((prize: LuckyDrawPrize) => {
    switch (prize) {
      case '1st':
        return t('submitttedBill.firstPrize');
      case '2nd':
        return t('submitttedBill.secondPrize');
      case '3rd':
        return t('submitttedBill.thirdPrize');
      case '4th':
        return t('submitttedBill.fourthPrize');
      default:
        return '';
    }
  }, [t]);

  useQuery(['getTokenWebsocket'], () => getTokenWebSocketService(), {
    onSuccess(data) {
      setTokenWs(data);
    },
    enabled: !!user,
  });

  const { data: luckyDrawDetailData, isLoading: loadingLuckyDrawMatch } = useQuery(['luckyDrawMatchDetail',
    socketData, currentPageHistoryTable
  ], () => getLuckyDrawMatchService(
    {
      limit: 2,
      page: currentPageHistoryTable,
      phone: socketData?.phoneNumber,
    }
  ), {
    enabled: !!socketData,
  });

  const { data: luckyDrawServiceData, isLoading: luckyDrawLoading } = useQuery(['luckyDrawService',
    socketData?.phoneNumber
  ], () => getLuckyDrawsService({
    limit: 5,
    phone: socketData?.phoneNumber,
  }), {
    enabled: !!socketData?.phoneNumber,
    onSuccess(response) {
      if (response?.data?.length) {
        setOpenDetail(true);
      } else {
        setOpenNotification(true);
      }
    },
  });

  const mappingFieldStatus = (status: LuckyDrawStatus) => {
    switch (status) {
      case 'valid':
        return t('system.valid');
      case 'invalid':
        return t('system.inValid');
      default:
        return t('submitttedBill.statusNew');
    }
  };

  const columns: ColumnsType<ProductInfoData> = [
    {
      title: 'STT',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: ProductInfoData, index) => (
        <Typography.Text>
          {index + 1}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.productName'),
      key: 'productName',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.productName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.modelName'),
      key: 'modelName',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.modelName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.seriesNumber'),
      key: 'seriesNumber',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.seriesNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.luckyDrawCode'),
      key: 'luckyDrawCode',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.buyDate'),
      key: 'buyDate',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {dayjs(data.buyDate).format('DD/MM/YYYY')}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.shopName'),
      key: 'shopName',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.shopName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      key: 'status',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {mappingFieldStatus(data.newStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.invalidType'),
      key: 'invalidType',
      render: (_name: string, data: ProductInfoData) => (
        <Typography.Text>
          {data.note}
        </Typography.Text>
      ),
    },
  ];

  const dataLuckyDrawMatch = useMemo(() => luckyDrawDetailData?.data?.map((item) => ({
    code: item?.mainData?.luckyCode,
    prizeType: item?.mainData?.prize,
    productName: item?.product?.productName,
    modelName: item?.product?.modelName,
    seriesNumber: item?.product?.seriesNumber,
  })), [luckyDrawDetailData]);

  const luckyDrawBillData:
    LuckyDrawBillData[] = useMemo(() => luckyDrawServiceData?.data?.map((item) => ({
      id: item.bill.id,
      name: item.bill.fullName,
      phone: formatPhoneVietnamese(item.bill.phone),
      email: item.bill.email,
      address: item.bill.address,
      wardName: item.bill.wardName,
      districtName: item.bill.districtName,
      provinceName: item.bill.provinceName,
      buyDate: item.bill.buyDate,
      shopAddress: item.bill.shopAddress,
      shopName: item.bill.shopName,
      createdAt: item.bill.createdAt,
      updatedAt: item.bill.updatedAt,
      images: item.bill.images
    })) || [], [luckyDrawServiceData]);

  const luckyDrawMatchTableData: ProductInfoData[] = useMemo(
    () => luckyDrawServiceData?.data?.map(((item) => ({
      id: item.luckyDrawData.id,
      productName: item.product.name,
      modelName: item.productModel.name,
      seriesNumber: item.submittedProduct.seriesNumber,
      code: item.luckyDrawData.luckyCode,
      buyDate: item.bill.buyDate,
      shopName: item.bill.shopName,
      newStatus: item.luckyDrawData.newStatus,
      note: item.luckyDrawData.note
    }))) || [],
    [luckyDrawServiceData]
  );

  const lastLuckyDrawBillItem: LuckyDrawBillData | undefined = useMemo(() => {
    if (luckyDrawBillData && luckyDrawBillData.length > 0) {
      return luckyDrawBillData[luckyDrawBillData.length - 1];
    }
    return {
      name: '',
      phone: '',
      email: '',
      address: '',
      wardName: '',
      districtName: '',
      provinceName: '',
      images: []
    };
  }, [luckyDrawBillData]);

  const listBillImageItem: string[] = useMemo(() => {
    if (luckyDrawBillData && luckyDrawBillData.length > 0) {
      return luckyDrawBillData.reduce((
        prev: LuckyDrawBillData[],
        curr
      ) => (prev.some((item) => item.id === curr.id)
        ? prev : [...prev, curr]), []).reduce((
          prev: string[],
          curr
        ) => ([...prev, ...curr.images.map((it) => it.url)]), []);
    }
    return [];
  }, [luckyDrawBillData]);

  useEffect(() => {
    if (tokenWs && wsHostApi) {
      // const { host, protocol } = window.location;
      const wsHost = `${wsHostApi.replace('http', 'ws')}/ws`;
      // dev
      // const wsHost = 'wss://electrolux-lucky-draw-dev-cms.3forcom.net/ws';
      wsInstance.current = socket(
        wsHost,
        { token: tokenWs },
        {
          onMessage: (data: string) => {
            const parsedData = JSON.parse(data);
            setOpenDetail(false);
            setOpenNotification(false);
            if (parsedData.event === 'hotlineNewCall') {
              setSocketData(parsedData.data);
            }
            if (parsedData.event === 'topupProcess') {
              dispatch(setTopupProcess({
                id: parsedData.data.topupForId,
                type: parsedData.data.type,
                status: parsedData.data.status
              }));
            }
            if (parsedData.event === 'smsProcess') {
              dispatch(setSendSMSProcess({
                id: parsedData.data.smsForId,
                status: parsedData.data.status
              }));
            }
            if (parsedData.event === 'smsAllProcess') {
              dispatch(setSendSMSAllProcess('success'));
            }
          },
        }
      );
      return () => {
        wsInstance.current?.close();
      };
    }
    return () => { };
  }, [dispatch, tokenWs, wsHostApi]);

  const columnsHistory: ColumnsType<HistoryGiftItemType> = [
    {
      title: 'STT',
      key: 'id',
      width: 65,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: HistoryGiftItemType, index) => (
        <Typography.Text>
          {index + 1}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.luckyDrawCode'),
      dataIndex: 'code',
      render: (_name: string, data: HistoryGiftItemType) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.prizeType'),
      dataIndex: 'prizeType',
      render: (_name: string, data: HistoryGiftItemType) => (
        <Typography.Text>
          {handlePrizeType(data.prizeType)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.productName'),
      dataIndex: 'productName',
      render: (_name: string, data: HistoryGiftItemType) => (
        <Typography.Text>
          {data.productName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.modelName'),
      dataIndex: 'modelName',
      render: (_name: string, data: HistoryGiftItemType) => (
        <Typography.Text>
          {data.modelName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.seriesNumber'),
      dataIndex: 'seriesNumber',
      render: (_name: string, data: HistoryGiftItemType) => (
        <Typography.Text>
          {data.seriesNumber}
        </Typography.Text>
      ),
    },
  ];

  return (
    <Layout>
      <Sider width={collapsed ? 80 : 250}>
        <div className="t-mainlayout_sidebar_header">
          <Image width={collapsed ? 46 : 120} src={IconLogo} preview={false} />
        </div>
        <div className="t-mainlayout_sidebar_menu">
          <Sidebar
            menuItems={menus}
            collapsed={collapsed}
            handleCollapsed={setCollapsed}
          />
        </div>
      </Sider>
      <Layout className="t-mainlayout_content">
        <Outlet />
      </Layout>
      <Modal
        width="15vw"
        open={openNotification}
        footer={null}
        closable
        onCancel={() => {
          setSocketData(undefined);
          setOpenNotification(false);
        }}
      >
        <div className="t-mainlayout_modalWrapPhone">
          <div className="t-mainlayout_modalWrapPhone-text">
            <Typography.Text
              style={{ fontWeight: '600', fontSize: '20px' }}
            >
              {t('system.incomingCall')}
            </Typography.Text>
          </div>
          <div className="t-mainlayout_modalWrapPhone-image">
            <Image width={30} src={userImage} />
          </div>
          <div className="t-mainlayout_modalWrapPhone-text">
            <Typography.Text
              style={
                {
                  fontWeight: '600',
                  fontSize: '16px',
                  color: '#ee5e52',
                }
              }
            >
              {formatPhoneVietnamese(socketData?.phoneNumber)}
            </Typography.Text>
          </div>
        </div>
      </Modal>
      <Modal
        width="90vw"
        open={openDetail}
        footer={null}
        onCancel={() => {
          setSocketData(undefined);
          setOpenDetail(false);
        }}
        closable
        maskClosable={false}
      >
        <Card title={<Title level={3}>{t('submitttedBill.customerInfo')}</Title>} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={8}>
              <Typography.Text strong>
                {t('system.name')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.name}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('system.phone')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.phone}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('system.email')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.email}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('submitttedBill.provinceName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.provinceName}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('submitttedBill.districtName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.districtName}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('submitttedBill.wardName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.wardName}
              </Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t('submitttedBill.address')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {lastLuckyDrawBillItem?.address}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card title={<Title level={3}>{t('submitttedBill.productInfo')}</Title>} style={{ marginTop: '16px' }}>
          <Table
            loading={luckyDrawLoading}
            columns={columns}
            dataSource={luckyDrawMatchTableData}
            pagination={false}
          />
        </Card>
        <Card title={<Title level={3}>{t('submitttedBill.billImage')}</Title>} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col
              span={24}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                margin: '-20px'
              }}
            >
              <Image.PreviewGroup>
                {listBillImageItem?.map((item, index) => (
                  <div
                    key={`t-mainlayout_imageWrapper-${index.toString()}`}
                    className="t-mainlayout_imageWrapper"
                  >
                    {item.slice(-3) === 'pdf' ? (
                      <Image
                        key={`billImage-${index.toString()}`}
                        src={pdfImage}
                        preview={false}
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => window.open(item, '_blank')}
                      />
                    ) : (
                      <Image
                        key={`billImage-${index.toString()}`}
                        src={item}
                      />
                    )}
                  </div>
                ))}
              </Image.PreviewGroup>
            </Col>
          </Row>
        </Card>
        <Card title={<Title level={3}>{t('luckyDrawMatch.prizeList')}</Title>} style={{ marginTop: '16px' }}>
          <Table
            className="u-mt-12"
            showSorterTooltip={false}
            dataSource={dataLuckyDrawMatch}
            columns={columnsHistory}
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
              total: luckyDrawDetailData?.meta.total,
              onChange: setCurrentPageHistoryTable,
              current: currentPageHistoryTable
            }}
            loading={loadingLuckyDrawMatch}
            scroll={{ x: 986 }}
          />
        </Card>
      </Modal>
    </Layout>
  );
};

export default Mainlayout;
