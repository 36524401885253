import axiosInstance from '../common/instance';

import {
  ConfigHeaderParams, ConfigHeaderTypes,
  InitialSystemData,
  SaveErrorConfigParams,
  SeoGeneralParamsTypes,
  SeoGeneralTypes,
  SystemGeneralData,
  SystemLocalesData,
  UpdateSystemGeneralParams,
  UpdateSystemLocalesParams
} from './types';

export const getConfigService = async (code: string): Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`config-${code}`);
  return res.data.data;
};

export const saveConfigService = async (code: string, data: ConfigHeaderParams): Promise<void> => {
  await axiosInstance.post(`config-${code}`, data);
};

export const getSeoGeneralService = async (): Promise<SeoGeneralTypes> => {
  const res = await axiosInstance.get('seo-general');
  return res.data.data;
};

export const postSeoGeneralService = async (params: SeoGeneralParamsTypes) => {
  const res = await axiosInstance.post('seo-general', params);
  return res.data;
};

export const getSystemInitialService = async (): Promise<InitialSystemData> => {
  const res = await axiosInstance.get('initial');
  return res.data.data;
};

export const getSystemLocalesService = async (): Promise<SystemLocalesData> => {
  const res = await axiosInstance.get('system-locales');
  return res.data.data;
};

export const updateSystemLocalesService = async (params: UpdateSystemLocalesParams):
  Promise<void> => {
  await axiosInstance.post('system-locales', params);
};

export const getSystemGeneralService = async ():
  Promise<SystemGeneralData> => {
  const res = await axiosInstance.get('system-general');
  return res.data.data;
};

export const updateSystemGeneralService = async (params: UpdateSystemGeneralParams):
  Promise<void> => {
  const bodyForm = new FormData();
  const gaids = params.gaIds?.split(',');
  gaids?.forEach((item) => {
    bodyForm.append('gaIds[]', item.trim());
  });
  const gtmids = params.gtmIds?.split(',');
  gtmids?.forEach((item) => {
    bodyForm.append('gtmIds[]', item.trim());
  });
  if (params.gMapId) {
    bodyForm.append('gMapId', params.gMapId);
  }
  if (params.fileFavicon) {
    bodyForm.append('fileFavicon', params.fileFavicon);
  }
  if (params.luckyDrawMatchRange) {
    bodyForm.append('luckyDrawMatchStart', params.luckyDrawMatchRange[0]);
    bodyForm.append('luckyDrawMatchEnd', params.luckyDrawMatchRange[1]);
  }
  if (params.luckyDrawMatchSecondRoundRange) {
    bodyForm.append('luckyDrawMatchSecondRoundStart', params.luckyDrawMatchSecondRoundRange[0]);
    bodyForm.append('luckyDrawMatchSecondRoundEnd', params.luckyDrawMatchSecondRoundRange[1]);
  }
  if (params.luckyDrawMatchThirdRoundRange) {
    bodyForm.append('luckyDrawMatchThirdRoundStart', params.luckyDrawMatchThirdRoundRange[0]);
    bodyForm.append('luckyDrawMatchThirdRoundEnd', params.luckyDrawMatchThirdRoundRange[1]);
  }
  if (params.luckyDrawFirstDuration) {
    bodyForm.append('luckyDrawFirstDuration', String(params.luckyDrawFirstDuration));
  }
  if (params.luckyDrawSecondDuration) {
    bodyForm.append('luckyDrawSecondDuration', String(params.luckyDrawSecondDuration));
  }
  if (params.luckyDrawThirdDuration) {
    bodyForm.append('luckyDrawThirdDuration', String(params.luckyDrawThirdDuration));
  }
  if (params.luckyDrawFourthDuration) {
    bodyForm.append('luckyDrawFourthDuration', String(params.luckyDrawFourthDuration));
  }
  if (params.endedEventTime) {
    bodyForm.append('endedEventTime', String(params.endedEventTime));
  }
  await axiosInstance.post('system-general', bodyForm);
};

export const getErrorConfigService = async (id: string):
  Promise<ConfigHeaderTypes> => {
  const res = await axiosInstance.get(`errors-pages/${id}`);
  return res.data.data;
};

export const saveErrorConfigService = async (id: string, data: SaveErrorConfigParams):
  Promise<void> => {
  await axiosInstance.post(`errors-pages/${id}`, data);
};
