import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteDetailModal from './deleteDetailModal';
import UpdateDetailModal from './updateDetailModal';

import HeaderPage from 'common/components/HeaderPage';

const SerialsManagement: React.FC = () => {
  const { t } = useTranslation();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.serials')}
      />
      <div className="t-mainlayout_wrapper">
        <div
          style={{
            display: 'flex',
            gap: '16px'
          }}
        >
          <Button
            style={{
              width: '50%',
              backgroundColor: '#ee5e52',
              borderRadius: '8px'
            }}
            onClick={() => setIsOpenDelete(true)}
          >
            <Typography.Text
              style={{ color: 'white' }}
            >
              {t('serials.deleteMachineSerials')}
            </Typography.Text>
          </Button>
          <Button
            style={{
              width: '50%',
              backgroundColor: '#6ba43a',
              borderRadius: '8px'
            }}
            onClick={() => setIsOpenUpdate(true)}
          >
            <Typography.Text
              style={{ color: 'white' }}
            >
              {t('serials.updateSerieNumber')}
            </Typography.Text>
          </Button>
        </div>
      </div>
      <DeleteDetailModal
        isOpen={isOpenDelete}
        handleClose={() => setIsOpenDelete(false)}
      />
      <UpdateDetailModal
        isOpen={isOpenUpdate}
        handleClose={() => setIsOpenUpdate(false)}
      />
    </>
  );
};

export default SerialsManagement;
