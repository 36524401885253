import { LuckyDrawValidRequest } from './type';

import axiosInstance from 'common/services/common/instance';

export const postLuckyDrawValidService = async (params?: LuckyDrawValidRequest)
  : Promise<void> => {
  await axiosInstance.post('lucky-draws/valid', params);
};

export const postLuckyDrawInvalidService = async (params?: LuckyDrawValidRequest)
  : Promise<void> => {
  await axiosInstance.post('lucky-draws/invalid', params);
};

export const resendLuckyCodeService = async (id: string)
  : Promise<void> => {
  await axiosInstance.post(`lucky-draws/${id}/resend-lucky-code`);
};
