import {
  CreateShopsParamsTypes,
  ShopsTypes,
  GetAllShopsParamsTypes,
  GetShopsByIdParamsTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

/* Shops */
export const getAllShopsService = async (params?: GetAllShopsParamsTypes)
  : Promise<APIPaginationResponse<ShopsTypes[]>> => {
  const res = await axiosInstance.get('shops', { params });
  return res.data;
};

export const getShopsByIdService = async ({ id }: GetShopsByIdParamsTypes)
  : Promise<ShopsTypes> => {
  const res = await axiosInstance.get(`shops/${id}`);
  return res.data.data;
};

export const createShopsService = async (params: CreateShopsParamsTypes)
  : Promise<{ id: number }> => {
  const res = await axiosInstance.post('shops', params);
  return res.data.data;
};

export const updateShopsService = async (id: number, params: CreateShopsParamsTypes)
  : Promise<unknown> => {
  const res = await axiosInstance.put(`shops/${id}`, params);
  return res.data;
};

export const deleteShopsService = async (params: { ids: number[] })
  : Promise<unknown> => {
  const res = await axiosInstance.delete('shops', { data: params });
  return res.data;
};
