import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col, Modal, Row, Typography, message
} from 'antd';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { clearPrizesService } from 'common/services/extends/serials';
import {
  ClearPrizesParams,
  LuckyDrawPrize,
  LuckyDrawRound,
} from 'common/services/extends/serials/types';

interface Props {
  isOpen: boolean;
  handleClose(): void;
}

const ClearPrizeslModal: React.FC<Props> = ({
  handleClose, isOpen
}) => {
  const { t } = useTranslation();

  const luckyDrawPrizeDummy = [
    {
      label: t('submitttedBill.firstPrize'),
      value: '1st',
    },
    {
      label: t('submitttedBill.secondPrize'),
      value: '2nd',
    },
    {
      label: t('submitttedBill.thirdPrize'),
      value: '3rd',
    },
    {
      label: t('submitttedBill.fourthPrize'),
      value: '4th',
    },
  ];

  const luckyDrawRoundDummy = [
    {
      label: t('luckyDrawMatch.roundOne'),
      value: 'round1',
    },
    {
      label: t('luckyDrawMatch.roundTwo'),
      value: 'round2',
    },
    {
      label: t('luckyDrawMatch.roundThree'),
      value: 'round3',
    },
  ];

  const clearPrizeSchema = yup.object().shape({
    phase: yup.number().typeError('Đây phải là một số')
      .min(1, 'Phase phải lớn hơn 0')
      .required('Thông tin này là bắt buộc'),
  });

  const mainMethod = useForm<ClearPrizesParams>({
    resolver: yupResolver(clearPrizeSchema),
    mode: 'onChange',
    defaultValues: {
      phase: undefined,
      prize: '4th' as LuckyDrawPrize,
      round: 'round1' as LuckyDrawRound
    }
  });

  const { mutate: clearPrizesMutate, isLoading: clearPrizesLoading } = useMutation(
    ['clearPrizes'],
    (params: ClearPrizesParams) => clearPrizesService(params),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const handleSubmit = async () => {
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }

    const mainData = mainMethod.getValues();

    const dataSubmit: ClearPrizesParams = {
      phase: Number(mainData.phase),
      prize: mainData.prize,
      round: mainData.round
    };

    try {
      clearPrizesMutate(dataSubmit);
    } catch (error: any) {
      message.error(t('message.deleteError'));
    }
  };

  return (
    <Modal
      title={(
        <Typography.Title level={3} style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {t('luckyDrawMatch.deleteLuckyDraw')}
        </Typography.Title>
      )}
      width="60vw"
      visible={isOpen}
      closable
      footer={null}
      onCancel={handleClose}
    >
      <div className="modal-container">
        <FormProvider {...mainMethod}>
          <Row
            gutter={16}
            className="u-mt-16"
          >
            <Col span={24}>
              <Typography.Text strong>
                {t('luckyDrawMatch.phase')}
              </Typography.Text>
              <Controller
                name="phase"
                defaultValue=""
                render={({
                  field: { value: fieldValue, onChange },
                  fieldState: { error },
                }) => (
                  <Input
                    type="number"
                    className="u-mt-8"
                    name="phase"
                    value={fieldValue}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </Col>
            <Col className="u-mt-8" span={24}>
              <Typography.Text strong>
                {t('luckyDrawMatch.prize')}
              </Typography.Text>
              <Controller
                name="prize"
                control={mainMethod.control}
                render={({ field, fieldState: { error } }) => (
                  <DropdownElement
                    locale="vi"
                    loading={clearPrizesLoading}
                    value={field.value}
                    error={error?.message}
                    size="large"
                    onChange={field.onChange}
                    options={luckyDrawPrizeDummy}
                  />
                )}
              />
            </Col>
            <Col className="u-mt-8" span={24}>
              <Typography.Text strong>
                {t('luckyDrawMatch.round')}
              </Typography.Text>
              <Controller
                name="round"
                control={mainMethod.control}
                render={({ field, fieldState: { error } }) => (
                  <DropdownElement
                    locale="vi"
                    loading={clearPrizesLoading}
                    value={field.value}
                    error={error?.message}
                    size="large"
                    onChange={field.onChange}
                    options={luckyDrawRoundDummy}
                  />
                )}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className="u-mt-16"
                type="primary"
                onClick={() => handleSubmit()}
                disabled={clearPrizesLoading}
              >
                {t('system.ok')}
              </Button>
            </Col>
          </Row>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default ClearPrizeslModal;
