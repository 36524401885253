import { useQuery } from 'react-query';

import { getProvincesByVietNameseService, getDistrictsService, getWardsService } from 'common/services/extends/location';
import { IProvince, IDistrict, IWard } from 'common/services/extends/location/types';

const useAddressOptions = (provinceCode?: string, districtCode?: string) => {
  const { data: provinces, isLoading: isLoadingProvinces } = useQuery<IProvince[]>(['getProvinces'], getProvincesByVietNameseService);
  const { data: districts, isLoading: isLoadingDistricts } = useQuery<IDistrict[]>(['getDistricts', provinceCode], () => (provinceCode ? getDistrictsService(provinceCode) : Promise.resolve([])));
  const { data: wards, isLoading: isLoadingWards } = useQuery<IWard[]>(['getWards', districtCode], () => (districtCode ? getWardsService(districtCode) : Promise.resolve([])));
  return {
    provinces: provinces?.map((province) => (
      { label: province.name, value: province.code })) as OptionType[],
    districts: districts?.map((district) => (
      { label: district.name, value: district.code })) as OptionType[],
    wards: wards?.map((ward) => (
      { label: ward.name, value: ward.code })) as OptionType[],
    isLoadingProvinces,
    isLoadingDistricts,
    isLoadingWards,
  };
};

export default useAddressOptions;
