import {
  ClearPrizesParams,
  DeleteMachineSerialsParams,
  SearchSerialData,
  UpdateSerialsNumberParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const searchSerialNumberService = async (machineSerial: string):
  Promise<SearchSerialData> => {
  const res = await axiosInstance.get(`black-list-series-numbers?machineSerial=${machineSerial}`);
  return res.data.data;
};

export const deleteMachineSerialService = async (params: DeleteMachineSerialsParams):
  Promise<void> => {
  await axiosInstance.delete('clear-data/delete-machine-serial', { params });
};

export const updateMachineSerialService = async (params: UpdateSerialsNumberParams):
  Promise<void> => {
  await axiosInstance.put('clear-data/update-series-number', params);
};

export const clearPrizesService = async (params: ClearPrizesParams):
  Promise<void> => {
  await axiosInstance.post('clear-data/clear-prizes', params);
};

export default searchSerialNumberService;
