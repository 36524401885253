import {
  DeleteSubmittedBillParams,
  EditSubmittedBillTypes,
  ExportDataTypes,
  ExportParamsTypes,
  GetSubmittedBillByIdParamsTypes,
  ProductModelsData,
  SubmittedBillTypes,
  UpdateModelParamsTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllSubmittedBillService = async (params?: BaseFilterParams
  & { newStatus?: string; sortBy?: string; sortType?: string, rounds?: string[] }):
  Promise<APIPaginationResponse<SubmittedBillTypes[]>> => {
  const res = await axiosInstance.get('submitted-bills', { params });
  return res.data;
};

export const getSubmittedBillByIdService = async ({ id }: GetSubmittedBillByIdParamsTypes)
  : Promise<SubmittedBillTypes> => {
  const res = await axiosInstance.get(`submitted-bills/${id}`);
  return res.data.data;
};

export const submittedBillExportService = async (params?: ExportParamsTypes & {
  rounds?: string[]
})
  : Promise<ExportDataTypes> => {
  const res = await axiosInstance.post('submitted-bills/export', params);
  return res.data.data;
};

export const updateSubmittedBillService = async (
  { id, ...params }: EditSubmittedBillTypes
): Promise<void> => {
  await axiosInstance.put(`submitted-bills/${id}`, params);
};

export const getProductModels = async (productId: number)
  : Promise<ProductModelsData[]> => {
  const res = await axiosInstance.get(`product-models?productId=${productId}`);
  return res.data.data.map((item: any) => item.productModelData);
};

export const updateModelSubmittedBillService = async (
  { id, ...params }: UpdateModelParamsTypes
): Promise<void> => {
  await axiosInstance.put(`submitted-bills/${id}/update-model`, params);
};

export const deleteSubmittedBillService = async (data: DeleteSubmittedBillParams)
  : Promise<void> => {
  await axiosInstance.delete('submitted-bills/remove-submitted-bill', { data });
};
