/* eslint-disable @typescript-eslint/no-unused-vars */
import { SaveOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { DropdownElement } from 'common/components/DropdownType';
import { getProductModels, updateModelSubmittedBillService } from 'common/services/extends/submittedBill';

type Props = {
  isOpen: boolean;
  billId: number;
  oldModelId: number;
  productId: number;
  seriesNumber: string;
  onClose: () => void;
  handleUpdateModelSuccess: () => void;
};

const DropdownModel: React.FC<Props> = ({
  isOpen, oldModelId, billId, seriesNumber,
  productId, handleUpdateModelSuccess, onClose
}) => {
  const { t } = useTranslation();
  const [newModelId, setNewModelId] = React.useState<number>();
  const { data: models } = useQuery(['getModels', productId], () => getProductModels(productId));
  const modelsOptions = models?.map((model) => ({ label: model.name, value: model.id }));
  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['updateModel'],
    updateModelSubmittedBillService,
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
        handleUpdateModelSuccess();
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  return (
    <Modal
      bodyStyle={{ paddingTop: '20px' }}
      open={isOpen}
      footer={(
        <Button
          type="primary"
          loading={updateLoading}
          disabled={!newModelId}
          onClick={() => {
            if (newModelId) {
              updateMutate({
                id: String(billId), productId, newModelId, oldModelId, seriesNumber
              });
            }
          }}
        >
          <SaveOutlined />
          {t('system.save')}
        </Button>
      )}
      title={t('submitttedBill.modelName')}
      onCancel={onClose}
    >
      <DropdownElement
        options={modelsOptions}
        placeholder={`${t('system.select')} ${t('submitttedBill.modelName')}`}
        locale="vi"
        loading={updateLoading}
        value={newModelId}
        isShowSearch
        noMargin
        size="middle"
        onChange={(value) => setNewModelId(value)}
      />
    </Modal>

  );
};

export default DropdownModel;
