/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ExportOutlined,
  EyeOutlined,
  PlusOutlined,
  CheckSquareFilled,
  DeleteOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, DatePicker, Modal, Space, Table, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useRoundDropdown from 'common/hooks/useRoundDropdown';
import { deleteSubmittedBillService, getAllSubmittedBillService, submittedBillExportService } from 'common/services/extends/submittedBill';
import {
  DeleteSubmittedBillParams,
  MatchesDataTypes,
  SubmittedBillProductTypes
} from 'common/services/extends/submittedBill/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateYYYYMMDD, formatPhoneVietnamese } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

type SubmittedBillDataTypes = {
  id: number;
  name: string;
  phone: string;
  shopName: string;
  buyDate: string;
  processing: number;
  valid: number;
  invalid: number;
  telco: string;
  products: SubmittedBillProductTypes[];
};

type ExportedSubmittedBillDataTypes = {
  createdStart: any
  createdEnded: any;
};

const SubmittedBillManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate, roleOther
}) => {
  const method = useForm<ExportedSubmittedBillDataTypes>({
    mode: 'onSubmit',
  });
  const deleteSchema = yup.object().shape({
    phone: yup.string().required('Thông tin này là bắt buộc'),
    modelName: yup.string().required('Thông tin này là bắt buộc'),
    seriesNumber: yup.string().required('Thông tin này là bắt buộc'),
  });
  const methodDelete = useForm<DeleteSubmittedBillParams>({
    mode: 'onSubmit',
    resolver: yupResolver(deleteSchema),
  });

  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');
  const navigate = useNavigate();

  /* Selectors */
  const {
    defaultPageSize, advancedFilter, eventRanges, currentRound
  } = useAppSelector((state) => state.system);

  const roundDropdownData = useRoundDropdown(roleOther);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDeletee] = useState(false);
  const [matchesDataPopup, setMatchesDataPopup] = useState<{
    isOpen: boolean;
    data: MatchesDataTypes[];
  }>({
    isOpen: false,
    data: []
  });
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const [statusFilter, setStatusFilter] = useState<string>();
  const [sortType, setSortType] = useState<{ field: 'id' | 'buyDate', type: string }>();
  const [roundFilter, setRoundFilter] = useState<string | undefined>(currentRound);

  const queryKey = ['getAllSubmittedBill', keyword, currentPage, currentView, sortType, selectedFilterList, statusFilter, roundFilter];

  /* Queries */
  const { data: submittedBill, isLoading } = useQuery(
    queryKey,
    () => getAllSubmittedBillService({
      keyword,
      page: currentPage,
      limit: currentView,
      sortBy: sortType?.field,
      sortType: sortType?.type,
      newStatus: statusFilter,
      // eslint-disable-next-line no-nested-ternary
      rounds: roundFilter ? roundFilter === 'all' ? ['round1', 'round2', 'round3'] : [roundFilter] : undefined,
      ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['submitted-bill-export'],
    (param: ExportedSubmittedBillDataTypes) => submittedBillExportService({ ...param, rounds: roundFilter === 'all' || !roundFilter ? ['round1', 'round2', 'round3'] : [roundFilter] }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
        setIsOpen(false);
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );
  const { mutate: removeMutate, isLoading: removeLoading } = useMutation(
    ['remove-submitted-bill'],
    deleteSubmittedBillService,
    {
      onSuccess() {
        message.success(t('message.deleteSuccess'));
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const onHandleSeeDetailMatches = (productsParam: SubmittedBillProductTypes[]) => {
    setMatchesDataPopup({
      isOpen: true,
      data: productsParam.reduce((acc: any[], cur) => ([
        ...acc,
        ...cur.luckyDrawData.matches
      ]), [])
    });
  };

  const detectPrize = (prizeParams: string) => {
    if (prizeParams === '1st') return t('submitttedBill.firstPrize');
    if (prizeParams === '2nd') return t('submitttedBill.secondPrize');
    if (prizeParams === '3rd') return t('submitttedBill.thirdPrize');
    if (prizeParams === '4th') return t('submitttedBill.fourthPrize');
    return '';
  };

  const detectStatusPrize = (statusParams: number) => {
    if (statusParams === 1) return t('submitttedBill.newStatus');
    if (statusParams === 7) return t('submitttedBill.processingStatus');
    if (statusParams === 13) return t('submitttedBill.approvedStatus');
    return '';
  };

  const matchesDataColumns: ColumnsType<MatchesDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.luckyCode'),
      dataIndex: 'luckyCode',
      key: 'luckyCode',
      sorter: {
        compare: (
          a: MatchesDataTypes,
          b: MatchesDataTypes
        ) => a.luckyCode.localeCompare(b.luckyCode)
      },
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.luckyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.prize'),
      dataIndex: 'prize',
      key: 'prize',
      sorter: {
        compare: (
          a: MatchesDataTypes,
          b: MatchesDataTypes
        ) => a.prize.localeCompare(b.prize)
      },
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {detectPrize(data.prize)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {detectStatusPrize(data.status)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.rangeStart'),
      dataIndex: 'rangeStart',
      key: 'rangeStart',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.rangeStart}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.rangeEnd'),
      dataIndex: 'rangeEnd',
      key: 'rangeEnd',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.rangeEnd}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.publishedAt'),
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.publishedAt}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.createdAt}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: MatchesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.updatedAt}
        </Typography.Text>
      ),
    }
  ];

  const checkWinPrize = (productsParam: SubmittedBillProductTypes[]) => {
    const prod = productsParam.reduce((acc: any[], cur) => ([
      ...acc,
      ...cur.luckyDrawData.matches
    ]), []);
    if (prod.length > 0) return true;
    return false;
  };

  /* Variables */
  const submittedBillStatus = [
    {
      label: t('submitttedBill.processing'),
      value: 'new'
    },
    {
      label: t('submitttedBill.valid'),
      value: 'valid'
    },
    {
      label: t('submitttedBill.invalid'),
      value: 'invalid'
    },
  ];
  const columns: ColumnsType<SubmittedBillDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'id', type: sortType?.type === 'asc' ? 'desc' : 'asc' });
        },
      }),
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: SubmittedBillDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 160,
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {formatPhoneVietnamese(data.phone)}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.telco'),
      dataIndex: 'telco',
      key: 'telco',
      width: 160,
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.telco}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.shopName'),
      dataIndex: 'shopName',
      width: 150,
      key: 'shopName',
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.shopName}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.buyDate'),
      dataIndex: 'buyDate',
      key: 'buyDate',
      width: 120,
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'buyDate', type: sortType?.type === 'asc' ? 'desc' : 'asc' });
        },
      }),
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: SubmittedBillDataTypes) => (
        <Typography.Text>
          {data.buyDate && dayjs(data.buyDate).format('DD/MM/YYYY')}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.winPrize'),
      dataIndex: 'winPrize',
      width: 150,
      key: 'winPrize',
      render: (_: string, data: SubmittedBillDataTypes) => (
        <div style={{ display: 'flex', paddingLeft: '20px' }}>
          {checkWinPrize(data.products) ? (
            <CheckSquareFilled style={{ fontSize: '20px', color: '#52c41a' }} />
          ) : null}
        </div>
        // <Typography.Text
        //   style={{ color: '#4a4a4a', textDecoration: 'underline', cursor: 'pointer' }}
        //   onClick={() => onHandleSeeDetailMatches(data.products)}
        // >

        //   {t('submitttedBill.matchesDetail')}
        // </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.processing'),
      dataIndex: 'processing',
      width: 110,
      align: 'center',
      key: 'processing',
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.processing}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.valid'),
      dataIndex: 'valid',
      width: 110,
      align: 'center',
      key: 'valid',
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.valid}
        </Typography.Text>
      ),
    },
    {
      title: t('submitttedBill.invalid'),
      dataIndex: 'invalid',
      width: 110,
      align: 'center',
      key: 'invalid',
      render: (_: string, data: SubmittedBillDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a' }}
        >
          {data.invalid}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (_name: string, data: SubmittedBillDataTypes) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.SUBMITTED_BILL_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const exportRange = useMemo(() => {
    if (!eventRanges) return undefined;
    switch (roundFilter) {
      case 'round1':
        return { min: eventRanges.first.start, max: eventRanges.first.end };
      case 'round2':
        return { min: eventRanges.second.start, max: eventRanges.second.end };
      case 'round3':
        return { min: eventRanges.third.start, max: eventRanges.third.end };
      default:
        return undefined;
    }
  }, [eventRanges, roundFilter]);

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('submittedBill', advancedFilter),
    [advancedFilter]
  );

  const submittedBillData = useMemo(() => (submittedBill?.data?.map((val) => ({
    id: val.billData?.id,
    name: val.billData?.fullName || '',
    phone: val.billData?.phone || '',
    telco: val.billData?.telco || '',
    shopName: val.billData?.shopName || '',
    buyDate: val.billData?.buyDate || '',
    processing: val.products.filter((item) => item.luckyDrawData.newStatus === 'new').length,
    valid: val.products.filter((item) => item.luckyDrawData.newStatus === 'valid').length,
    invalid: val.products.filter((item) => item.luckyDrawData.newStatus === 'invalid').length,
    products: val.products,
  })) || []), [submittedBill]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const deleteBill = (data: DeleteSubmittedBillParams) => {
    removeMutate(data);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList, statusFilter]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = async () => {
    const result = await method.trigger();
    if (!result) {
      return;
    }

    const createStartParams = method.getValues('createdStart');
    const createEndedParams = method.getValues('createdEnded');

    const param = {
      createdStart: dayjs(`${formatDateYYYYMMDD(dayjs(createStartParams).toDate())} 00:00:00:000`).toISOString(),
      createdEnded: dayjs(`${formatDateYYYYMMDD(dayjs(createEndedParams).toDate())} 23:59:59:999`).toISOString(),
    };
    exportMutate(param);
  };

  useEffect(() => {
    if (exportRange) {
      method.reset({
        createdStart: dayjs(exportRange.min),
        createdEnded: dayjs(exportRange.max)
      });
    }
  }, [exportRange, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.submittedBill')}
        rightHeader={(
          <Space>
            {roleOther.includes(roles.SUBMITTED_BILL_REMOVE) && (
              <Button
                style={{
                  backgroundColor: '#ee5e52',
                  borderRadius: '4px',
                  color: '#fff'
                }}
                onClick={() => setIsOpenDeletee(true)}
              >
                <DeleteOutlined />
                {t('submitttedBill.removeSubmittedBill')}
              </Button>
            )}
            <Button
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.SUBMITTED_BILL_EXPORT)}
              onClick={() => setIsOpen(true)}
            >
              <ExportOutlined />
              {t('system.exportReport')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'phone', 'shopName', 'buyDate', 'winPrize', 'processing', 'valid', 'invalid', 'action'],
            columns,
            pageData: submittedBillData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: submittedBill?.meta.total,
            filterFields,
            noDeleteLanguage: true,
            noBaseCol: true
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
            extendedFilter: <Space>
              <DropdownElement
                noMargin
                size="middle"
                options={submittedBillStatus}
                placeholder={`${t('system.select')} ${t('system.status')}`}
                locale="vi"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e)}
              />
              <DropdownElement
                noMargin
                size="middle"
                options={roundDropdownData}
                placeholder={`${t('system.select')} ${t('luckyDrawMatch.round')}`}
                locale="vi"
                value={roundFilter}
                onChange={(e) => setRoundFilter(e)}
              />
            </Space>
          }}
          noCheckbox
        />
      </div>
      <Modal
        open={isOpen}
        centered
        footer={null}
        onCancel={() => {
          setIsOpen(false);
          method.reset();
        }}
      >
        <FormProvider<ExportedSubmittedBillDataTypes> {...method}>
          <form noValidate>
            <div className="u-mt-16">
              <div style={{ marginTop: '24px' }}>
                <Typography.Text
                  style={{ color: '#4a4a4a' }}
                >
                  {t('submitttedBill.createdStart')}
                </Typography.Text>
                <Controller
                  name="createdStart"
                  control={method.control}
                  render={({ field }) => (
                    <DatePicker
                      size="large"
                      value={dayjs(field.value)}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      disabledDate={(d) => (exportRange ? (d < dayjs(exportRange.min)
                        || d > dayjs(exportRange.max)) : false)}
                      style={{ width: '100%' }}
                      allowClear={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="u-mt-16">
              <Typography.Text
                style={{ color: '#4a4a4a' }}
              >
                {t('submitttedBill.createdEnd')}
              </Typography.Text>
              <Controller
                name="createdEnded"
                control={method.control}
                render={({ field }) => (
                  <DatePicker
                    size="large"
                    value={dayjs(field.value)}
                    onChange={field.onChange}
                    format="DD/MM/YYYY"
                    disabledDate={(d) => (exportRange ? (d < dayjs(exportRange.min)
                      || d > dayjs(exportRange.max)) : false)}
                    style={{ width: '100%' }}
                    allowClear={false}
                  />
                )}
              />
            </div>
            <div
              className="u-mt-16"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                disabled={!getPermission(roleOther, roles.SUBMITTED_BILL_EXPORT) || exportLoading}
                type="primary"
                onClick={handleExport}
              >
                <PlusOutlined />
                {t('system.exportReport')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal
        open={isOpenDelete}
        centered
        footer={null}
        onCancel={() => {
          setIsOpenDeletee(false);
          methodDelete.reset();
        }}
      >
        <FormProvider<DeleteSubmittedBillParams> {...methodDelete}>
          <form noValidate>
            <div className="u-mt-16">
              <div style={{ marginTop: '24px' }}>
                <Typography.Text
                  style={{ color: '#4a4a4a' }}
                >
                  {t('system.phone')}
                </Typography.Text>
                <Controller
                  name="phone"
                  control={methodDelete.control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Input
                      className="u-mt-8"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      size="large"
                    />
                  )}
                />
              </div>
            </div>
            <div className="u-mt-16">
              <Typography.Text
                style={{ color: '#4a4a4a' }}
              >
                {t('submitttedBill.modelName')}
              </Typography.Text>
              <Controller
                name="modelName"
                control={methodDelete.control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Input
                    className="u-mt-8"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </div>
            <div className="u-mt-16">
              <Typography.Text
                style={{ color: '#4a4a4a' }}
              >
                {t('submitttedBill.seriesNumber')}
              </Typography.Text>
              <Controller
                name="seriesNumber"
                control={methodDelete.control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Input
                    className="u-mt-8"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    size="large"
                  />
                )}
              />
            </div>
            <div
              className="u-mt-16"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="primary"
                loading={removeLoading}
                onClick={() => {
                  Modal.confirm({
                    className: 't-pagetable_deleteLanguageModal',
                    autoFocusButton: 'cancel',
                    okText: t('system.ok'),
                    cancelText: t('system.cancel'),
                    cancelButtonProps: {
                      type: 'primary',
                    },
                    okButtonProps: {
                      type: 'default',
                    },
                    title: t('message.confirmDeleteBill'),
                    onOk: methodDelete.handleSubmit(deleteBill),
                  });
                }}
              >
                <DeleteOutlined />
                {t('system.delete')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Modal>
      <Modal
        width={1200}
        centered
        footer={null}
        open={matchesDataPopup.isOpen}
        onCancel={() => {
          setMatchesDataPopup({
            isOpen: false,
            data: []
          });
        }}
      >
        <Typography.Title level={4} style={{ paddingBottom: '30px' }}>
          {t('submitttedBill.titleMatchesDetail')}
        </Typography.Title>
        <div style={{ width: '100%', height: '80vh', overflow: 'auto' }}>
          <Table
            dataSource={matchesDataPopup.data}
            columns={matchesDataColumns}
            showSorterTooltip={false}
            pagination={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default SubmittedBillManagement;
