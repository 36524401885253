export const SocketStatus = {
  CONNECTING: 1,
  CONNECTED: 2,
  DISCONNECTED: 3,
};
interface WsOptions {
  onMessage?: (data: string) => void;
  onStateChange?: (status: number) => void;

  pingInterval: number;
  pongTimeout: number;
  initBackoff: number;
}

const DEFAULT_OPTIONS: WsOptions = {
  pingInterval: 30000,
  pongTimeout: 5000,
  initBackoff: 1000,
};

export type SocketInstanceType = { ws: WebSocket, close: () => void };

const socket = (
  url: string,
  data: { [key: string]: any },
  options: Partial<WsOptions> = {}
): SocketInstanceType => {
  const opts: WsOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const qs = Object.entries(data)
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&');

  let ws: WebSocket;
  let backOff = opts.initBackoff / 2;
  let backOffHandle: NodeJS.Timer | null;
  let forceClose = false;

  // eslint-disable-next-line prefer-const
  ws = new WebSocket(`${url}${qs ? '?' : ''}${qs}`);
  function initWs() {
    opts.onStateChange?.(SocketStatus.CONNECTING);
    backOffHandle = null;

    let intervalHandle: NodeJS.Timer;
    let pongTimeoutHandle: NodeJS.Timeout;

    ws.onopen = () => {
      opts.onStateChange?.(SocketStatus.CONNECTED);

      if (backOff >= opts.initBackoff) {
        console.log('WS: Reconnected');

        console.log('WS: Reset backoff to default');
        backOff = opts.initBackoff / 2;
      } else {
        console.log('WS: Opened connection');
      }

      intervalHandle = setInterval(() => {
        ws.send('ping');

        pongTimeoutHandle = setTimeout(() => {
          console.log('WS: Server not responding');
          clearInterval(intervalHandle);
          ws.close();
        }, opts.pongTimeout);
      }, opts.pingInterval);
    };

    ws.onerror = (event) => {
      console.log('WS Error:', event);
    };

    ws.onclose = () => {
      opts.onStateChange?.(SocketStatus.DISCONNECTED);

      if (forceClose) {
        console.log('WS: Force closed');
        return;
      }

      if (backOff >= opts.initBackoff) {
        console.log('WS: Backoff reconnect');
      } else {
        clearTimeout(pongTimeoutHandle);
        clearInterval(intervalHandle);
        console.log('WS: Closed connection');
      }

      // reconnect
      backOff = Math.min(backOff * 2, 60000);
      console.log('WS: Reconnect in', backOff, 'ms');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      backOffHandle = setTimeout(initWs, backOff);
    };

    ws.onmessage = function (event) {
      if (event.data === 'pong') {
        clearTimeout(pongTimeoutHandle);
      } else {
        opts.onMessage?.(event.data);
      }
    };
  }

  // handle reconnect when detect network change
  const onNetworkChange = () => {
    if (navigator.onLine) {
      console.log('WS: Network online');

      if (backOffHandle) {
        clearTimeout(backOffHandle);
        initWs();
      }
    }
  };

  initWs();

  window.addEventListener('online', onNetworkChange);

  return {
    ws,
    close: () => {
      forceClose = true;
      ws.close();
      window.removeEventListener('online', onNetworkChange);
    },
  };
};

export default socket;
